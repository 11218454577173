import {createStore} from "vuex";
import createPersistedState from "vuex-persistedstate"

export default createStore({
    state() {
        return {
            userInfo: {},
            progress: {},
            userNodeRecordId: '',
            knowledgePointId: 11,
            browsingTime: 20,
            listInfo: {}
        }
    },
    mutations: {
        userInfoState(state, value) {
            state.userInfo = value;
        },
        progressState(state, value) {
            state.progress = value;
        },
        nodeRecordState(state, value) {
            state.userNodeRecordId = value
        },
        knowledgeState(state, value) {
            state.knowledgePointId = value
        },
        browsingTimeState(state, value) {
            state.browsingTime = value
        },
        listInfoState(state, value) {
            state.listInfo = value
        },
        // eslint-disable-next-line no-unused-vars
        resetStore(state) {
            Object.assign(state, {
                userInfo: {},
                progress: {},
                userNodeRecordId: '',
                knowledgePointId: 11,
                browsingTime: 20,
                listInfo: {}
            })
        }
    },
    actions: {
        userInfoChange(context, value) {
            context.commit('userInfoState', value)
        },
        progressChange(context, value) {
            context.commit('progressState', value)
        },
        nodeChange(context, value) {
            context.commit('nodeRecordState', value)
        },
        knowledgeChange(context, value) {
            context.commit('knowledgeState', value)
        },
        browsingChange(context, value) {
            context.commit('browsingTimeState', value)
        },
        listInfo(context, value) {
            context.commit('listfoState', value)
        },
        resetStore(context) {
            context.commit("resetStore")
        }
    },
    modules: {},
    plugins: [createPersistedState({
        storage: window.sessionStorage
    })]
});
