import moudle1TopImg from '@/assets/home/moudle_1_top_img.png';

// 插画图导入
import balls from '@/assets/paster/balls.png'

import bottomGroup from '@/assets/paster/bottom_group.png'
import bottomGroup1 from '@/assets/paster/bottom_group1.png'
import bottomGroup3 from '@/assets/paster/bottom_group3.png'

import boxes from '@/assets/paster/boxes.png'
import boxes1 from '@/assets/paster/boxes1.png'
import broadcast from '@/assets/paster/broadcast.png'

import car from '@/assets/paster/car.png'
import car1 from '@/assets/paster/cars1.png'
import cloud from '@/assets/paster/cloud.png'
import cloud1 from '@/assets/paster/cloud1.png'
import computer from '@/assets/paster/computer.png'

import creame from '@/assets/paster/creame.png'
import eleCloud from '@/assets/paster/eleCloud.png'
import Frame from '@/assets/paster/Frame.png'
import houses from '@/assets/paster/houses.png'
import houses1 from '@/assets/paster/houses1.png'
import houses2 from '@/assets/paster/houses2.png'
import houses3 from '@/assets/paster/houses3.png'

import mindMap from '@/assets/paster/mind-map.png'
import mind from '@/assets/paster/mind.png'
import phone from '@/assets/paster/phone1.png'
import search from '@/assets/paster/search.png'

import titleBack from '@/assets/home/titleBack.png'
import steel from '@/assets/home/steel.png'

// import arrowDown from '@/assets/moudleArrow/arrowDown'
// import arrowUp from '@/assets/moudleArrow/arrow_up.png'
// import arrowLeft from '@/assets/moudleArrow/arrow_left.png'
import arrowRight from '@/assets/moudleArrow/arrow_right.png'

import green_arrow from '@/assets/moudleArrow/green.png'


export default [{
        type: 'img', // 图片类型
        src: moudle1TopImg,
        style: {
            position: "absolute",
            top: "-28px",
            left: "-52px",
            width: "686px",
            height: "249px",
        }
    },
    {
        type: 'img',
        src: car,
        style: {
            position: "absolute",
            top: "315px",
            right: "320px"
        }
    },
    {
        type: 'img',
        src: steel,
        style: {
            position: "absolute",
            top: "604px",
            left: "606px"
        }
    },
    {
        type: 'img',
        src: steel,
        style: {
            position: "absolute",
            top: "78px",
            left: "961px"
        }
    },
    {
        type: 'img',
        src: steel,
        style: {
            position: "absolute",
            top: "614px",
            right: "524px"
        }
    },
    {
        type: 'img',
        src: titleBack,
        style: {
            position: "absolute",
            top: "298px",
            left: "-11px"
        }
    },
    {
        type: 'img',
        src: titleBack,
        style: {
            position: "absolute",
            top: "635px",
            left: "230px"
        }
    },
    {
        type: 'img',
        src: titleBack,
        style: {
            position: "absolute",
            top: "450px",
            left: "510px"
        }
    },
    {
        type: 'img',
        src: titleBack,
        style: {
            position: "absolute",
            top: "50px",
            left: "815px"
        }
    },
    {
        type: 'img',
        src: titleBack,
        style: {
            position: "absolute",
            top: "400px",
            left: "1177px"
        }
    },
    {
        type: 'img',
        src: titleBack,
        style: {
            position: "absolute",
            bottom: "665px",
            right: "830px"
        }
    },
    {
        type: 'img',
        src: titleBack,
        style: {
            position: "absolute",
            bottom: "78px",
            right: "673px"
        }
    },
    {
        type: 'img',
        src: titleBack,
        style: {
            position: "absolute",
            bottom: "333px",
            right: "545px"
        }
    },
    {
        type: 'img',
        src: titleBack,
        style: {
            position: "absolute",
            bottom: "327px",
            right: "187px"
        }
    },
    {
        type: 'img',
        src: eleCloud,
        style: {
            position: "absolute",
            top: "460px",
            left: "1318px"
        }
    },
    {
        type: 'img',
        src: Frame,
        style: {
            position: "absolute",
            top: "638px",
            left: "541px"
        }
    },
    {
        type: 'img',
        src: houses,
        style: {
            position: "absolute",
            bottom: "100px",
            right: "776px"
        }
    },
    {
        type: 'img',
        src: houses1,
        style: {
            position: "absolute",
            bottom: "-5px",
            right: "313px"
        }
    },
    {
        type: 'img',
        src: houses2,
        style: {
            position: "absolute",
            top: "80px",
            left: "1040px"
        }
    },
    {
        type: 'img',
        src: houses3,
        style: {
            position: "absolute",
            top: "120px",
            left: "1371px"
        }
    },
    {
        type: 'img',
        src: mindMap,
        style: {
            position: "absolute",
            top: "40px",
            right: "660px"
        }
    },
    {
        type: 'img',
        src: mind,
        style: {
            position: "absolute",
            top: "40px",
            right: "50px"
        }
    },
    {
        type: 'img',
        src: phone,
        style: {
            position: "absolute",
            top: "272px",
            left: "960px"
        }
    },
    {
        type: 'img',
        src: search,
        style: {
            position: "absolute",
            top: "71px",
            right: "1011px"
        }
    },
    {
        type: 'img',
        src: creame,
        style: {
            position: "absolute",
            top: "265px",
            right: "110px"
        }
    },
    {
        type: 'img',
        src: car1,
        style: {
            position: "absolute",
            bottom: "240px",
            left: "94px"
        }
    },
    {
        type: 'img',
        src: cloud,
        style: {
            position: "absolute",
            bottom: "260px",
            right: "1200px"
        }
    },
    {
        type: 'img',
        src: cloud1,
        style: {
            position: "absolute",
            bottom: "330px",
            right: "1300px"
        }
    },
    {
        type: 'img', // 电脑
        src: computer,
        style: {
            position: "absolute",
            bottom: "59px",
            left: "104px"
        }
    },
    {
        type: 'img',
        src: boxes,
        style: {
            position: "absolute",
            bottom: "510px",
            right: "685px",
        }
    },
    {
        type: 'img',
        src: boxes1,
        style: {
            position: "absolute",
            top: "321px",
            left: "579px",
        }
    },
    {
        type: 'img',
        src: broadcast,
        style: {
            position: "absolute",
            top: "340px",
            left: "308px",
        }
    },
    {
        type: 'img',
        src: bottomGroup,
        style: {
            position: "absolute",
            bottom: '-24px',
            left: '24px',
            zIndex: "100"
        }
    },
    {
        type: 'img',
        src: bottomGroup1, // 绿地
        style: {
            position: "absolute",
            bottom: '-18px',
            right: '605px',
            zIndex: '-1'
        }
    },
    {
        type: 'img',
        src: bottomGroup3,
        style: {
            position: "absolute",
            bottom: '-9px',
            right: '8px',
            zIndex: '98'
        }
    },
    {
        type: 'img',
        src: balls,
        style: {
            position: "absolute",
            top: "506px",
            left: "660px"
        }
    }, {
        type: 'img',
        src: titleBack,
        style: {
            position: "absolute",
            bottom: "-8px",
            right: "1091px"
        }
    },
    {
        type: 'tip', // 黄色的标语卡片类型
        text: '开始第1阶段学习',
        style: {
            position: "absolute",
            top: "240px",
            left: "-13px",
        }
    },

    {
        type: 'tip', // 标语卡片类型
        text: '结束第1阶段学习',
        style: {
            position: "absolute",
            top: "601px",
            right: "187px",
            color: "#000",
            background: "#FFDA26"
        }
    },
    {
        type: 'tip',
        text: '消费者分析与平台选择',
        style: {
            position: "absolute",
            top: "304px",
            left: "-26px",
            width: "92px",
            height: "53px",
            background: 'none'
        }
    },
    {
        type: 'tip',
        text: "团队组建&选品划分",
        style: {
            position: "absolute",
            top: "642px",
            left: "214px",
            width: "92px",
            height: "53px",
            background: 'none'
        }
    },
    {
        type: 'tip',
        text: "引流&预热",
        style: {
            position: "absolute",
            top: "464px",
            left: "493px",
            width: "92px",
            height: "53px",
            background: 'none'
        }
    },
    {
        type: 'tip',
        text: "脚本准备",
        style: {
            position: "absolute",
            top: "63px",
            left: "801px",
            width: "92px",
            height: "53px",
            background: 'none'
        }
    },
    {
        type: 'tip',
        text: "营销工具准备",
        style: {
            position: "absolute",
            top: "415px",
            left: "1161px",
            width: "92px",
            height: "53px",
            background: 'none'
        }
    },
    {
        type: 'tip',
        text: "其他准备",
        style: {
            position: "absolute",
            top: "660px",
            right: "1076px",
            width: "92px",
            height: "53px",
            background: 'none'
        }
    },
    {
        type: 'tip',
        text: "直播流程",
        style: {
            position: "absolute",
            top: "-14px",
            right: "814px",
            width: "92px",
            height: "53px",
            background: 'none'
        }
    },
    {
        type: 'tip',
        text: "直播监测与协助",
        style: {
            position: "absolute",
            top: "565px",
            right: "657px",
            width: "92px",
            height: "53px",
            background: 'none'
        }
    },
    {
        type: 'tip',
        text: "直播复盘",
        style: {
            position: "absolute",
            top: "316px",
            right: "531px",
            width: "92px",
            height: "53px",
            background: 'none'
        }
    },
    {
        type: 'tip',
        text: "内容宣传",
        style: {
            position: "absolute",
            top: "322px",
            right: "171px",
            width: "92px",
            height: "53px",
            background: 'none'
        }
    },
    {
        type: 'menuTip', // 菜单卡片 带有交互的卡片
        text: '消费者分析',
        sync: 'A-1',
        style: {
            position: "absolute",
            bottom: "354px",
            left: "79px",
            zIndex: 102
        },
        menusList: []
    },
    {
        type: 'menuTip',
        text: "直播平台选择",
        sync: 'A-2',
        style: {
            position: "absolute",
            left: "94px",
            bottom: "159px",
            zIndex: 101
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "辅助团队",
        sync: 'A-3',
        style: {
            position: "absolute",
            left: "325px",
            bottom: "204px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "主播",
        sync: 'A-4',
        style: {
            position: "absolute",
            left: "285px",
            bottom: "412px",
            zIndex: 103
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "选品划分",
        sync: 'A-5',
        style: {
            position: "absolute",
            left: "569px",
            top: "259px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "自有矩阵引流",
        sync: 'A-6',
        style: {
            position: "absolute",
            left: "567px",
            top: "582px",
            zIndex: 101
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "平台直播引流",
        sync: 'A-7',
        style: {
            position: "absolute",
            left: "816px",
            top: "559px",
            zIndex: "101"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "微信直播引流",
        sync: 'A-8',
        style: {
            position: "absolute",
            left: "816px",
            top: "455px",
            zIndex: 103
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "直播倒计时宣传",
        sync: 'A-9',
        style: {
            position: "absolute",
            left: "816px",
            top: "354px",
            zIndex: 103
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "参与优惠券派发",
        sync: 'A-10',
        style: {
            position: "absolute",
            left: "816px",
            top: "256px",
            zIndex: 103
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "流程设计",
        sync: 'A-11',
        style: {
            position: "absolute",
            left: "937px",
            top: "50px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "预算及玩法规划",
        sync: 'A-12',
        style: {
            position: "absolute",
            left: "1218px",
            top: "199px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "直播话术设计",
        sync: 'A-13',
        style: {
            position: "absolute",
            left: "1218px",
            top: "283px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "直接转化",
        sync: 'A-14',
        style: {
            position: "absolute",
            left: "1217px",
            bottom: "180px",
            zIndex: 103
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "复购留客",
        sync: 'A-15',
        style: {
            position: "absolute",
            left: "1217px",
            bottom: "82px",
            zIndex: "101"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "商品关联推广 ",
        sync: 'A-16',
        style: {
            position: "absolute",
            left: "1370px",
            bottom: "80px",
            zIndex: "101"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "客服就位",
        sync: 'A-17',
        style: {
            position: "absolute",
            right: "1039px",
            bottom: "222px",
            zIndex: 101
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "商品提前打包",
        sync: 'A-18',
        style: {
            position: "absolute",
            right: "1011px",
            bottom: "315px",
            zIndex: 101
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "助理及运营侯场",
        sync: 'A-19',
        style: {
            position: "absolute",
            right: "999px",
            bottom: "416px",
            zIndex: 101
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "主播话术熟悉",
        sync: 'A-20',
        style: {
            position: "absolute",
            right: "1012px",
            bottom: "515px",
            zIndex: 101
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "软硬件准备",
        sync: 'A-21',
        style: {
            position: "absolute",
            right: "1026px",
            bottom: "620px",
            zIndex: 101
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "开场暧场",
        sync: 'A-22',
        style: {
            position: "absolute",
            right: "787px",
            bottom: "565px",
            zIndex: 102
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "商品展示",
        sync: 'A-23',
        style: {
            position: "absolute",
            right: "787px",
            bottom: "498px",
            zIndex: 101
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "商品试用及体验评价",
        sync: 'A-24',
        style: {
            position: "absolute",
            right: "716px",
            bottom: "430px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "营销工具使用",
        sync: 'A-25',
        style: {
            position: "absolute",
            right: "758px",
            bottom: "363px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "互动",
        sync: 'A-26',
        style: {
            position: "absolute",
            right: "812px",
            bottom: "297px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "交流",
        sync: 'A-27',
        style: {
            position: "absolute",
            right: "812px",
            bottom: "233px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "数据监测",
        sync: 'A-28',
        style: {
            position: "absolute",
            right: "500px",
            bottom: "141px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "直播协助",
        sync: 'A-29',
        style: {
            position: "absolute",
            right: "500px",
            bottom: "240px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "直播数据分析",
        sync: 'A-30',
        style: {
            position: "absolute",
            right: "472px",
            top: "250px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "电商数据分析",
        sync: 'A-31',
        style: {
            position: "absolute",
            right: "472px",
            top: "186px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "直播过程分析",
        sync: 'A-32',
        style: {
            position: "absolute",
            right: "471px",
            top: "122px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "粉丝评价跟踪分析",
        sync: 'A-33',
        style: {
            position: "absolute",
            right: "443px",
            top: "57px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "话术迭代",
        sync: 'A-34',
        style: {
            position: "absolute",
            top: "114px",
            right: "297px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "直播氛围",
        sync: 'A-35',
        style: {
            position: "absolute",
            top: "177px",
            right: "298px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "流程迭代",
        sync: 'A-36',
        style: {
            position: "absolute",
            top: "238px",
            right: "298px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "直播视频精华剪辑",
        sync: 'A-37',
        style: {
            position: "absolute",
            top: "470px",
            right: "86px",
            zIndex: "102"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "渠道内容投放宣传",
        sync: 'A-38',
        style: {
            position: "absolute",
            top: "543px",
            right: "86px",
            zIndex: "101"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        id: '1',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "365px",
            left: "30px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '2',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "563px",
            left: "30px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '3',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "517px",
            left: "237px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '4',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "307px",
            left: "235px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '5',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "263px",
            left: "499px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '6',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "585px",
            left: "504px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '7',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "561px",
            left: "766px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '8',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "458px",
            left: "768px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '9',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "359px",
            left: "767px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '10',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "259px",
            left: "768px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '11',
        type: 'arrow',
        direction: 'Up',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "131px",
            left: "964px",
            transform: 'rotateZ(270deg)',
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '12',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "201px",
            left: "1170px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '13',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "285px",
            left: "1171px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '14',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "540px",
            left: "1170px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '15',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "637px",
            left: "1170px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '16',
        type: 'arrow',
        direction: 'Up',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "695px",
            left: "1410px",
            transform: 'rotateZ(270deg)',
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '17',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "497px",
            right: "1142px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '18',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "404px",
            right: "1142px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '19',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "303px",
            right: "1142px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '20',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "204px",
            right: "1142px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '21',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "100px",
            right: "1142px",
            background: `url(${green_arrow}) center no-repeat`
        }
    }, {
        id: '22',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "156px",
            right: "882px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '23',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "225px",
            right: "882px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '24',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "291px",
            right: "882px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '25',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "358px",
            right: "882px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '26',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "424px",
            right: "882px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '27',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "487px",
            right: "882px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '28',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "579px",
            right: "598px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '29',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "479px",
            right: "598px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '30',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "254px",
            right: "598px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '31',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "190px",
            right: "598px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '32',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "125px",
            right: "598px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '33',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "60px",
            right: "598px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '34',
        type: 'arrow',
        direction: 'Left',
        style: {
            position: "absolute",
            top: "118px",
            right: "246px",
            transform: 'rotateY(180deg)',
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '35',
        type: 'arrow',
        direction: 'Left',
        style: {
            position: "absolute",
            top: "181px",
            right: "246px",
            transform: 'rotateY(180deg)',
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '36',
        type: 'arrow',
        direction: 'Left',
        style: {
            position: "absolute",
            top: "243px",
            right: "246px",
            transform: 'rotateY(180deg)',
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '37',
        type: 'arrow',
        direction: 'Right',
        style: {
            position: "absolute",
            top: "474px",
            right: "238px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '38',
        type: 'arrow',
        direction: 'Right',
        style: {
            position: "absolute",
            top: "549px",
            right: "238px",
            background: `url(${green_arrow}) center no-repeat`
        }
    }
];