<template>
  <div class="header">
    <div class="logo"></div>
    <div v-if="signal == 1" class="titleSmall">STEP直播电商智慧手册</div>
    <div :class="`nav ${signal == 1 ? 'navSearch' : ''}`">
      <div
        @click="jumpContent(1)"
        :class="`moudle ${currentIndex == 1 && 'active'}`"
      >
        <span>步步为营</span>
        <span class="textde"></span>
      </div>
      <div
        @click="jumpContent(2)"
        :class="`moudle ${currentIndex == 2 && 'active'}`"
      >
        <span>学富五车</span>
        <span class="textde"></span>
      </div>
      <div
        @click="jumpContent(3)"
        :class="`moudle last_moudle ${currentIndex == 3 && 'active'}`"
      >
        <span>有章有法</span>
        <span class="textde"></span>
      </div>
    </div>
    <div :class="`title ${signal == 1 ? 'titleSearch' : ''}`">
      STEP直播电商智慧手册 · <span> 翻翻查</span>
    </div>
    <div class="search">
      <input
        placeholder="请输入知识点"
        type="text"
        v-model="keyWords"
        @input="getRelated"
        @focus="changeHeader"
      />
      <div class="searchButton"></div>
      <!-- <img src="@/assets/search.png" alt=""> -->
      <div v-if="signal == 1 && contentDetail === null" class="searchResult">
        <div
          v-for="(item, index) in newListData"
          :class="`result_menu ${item.id <= knowledgePointId && 'result_menu_active'}`"
          :key="index"
          @click="getContent(item)"
        >
          {{ item.knowledgePointTitle }}
        </div>
        <div class="close_button" @click="handleClose">X</div>
      </div>
    </div>
    <div class="user">
      <div class="cert" @click="showCert"></div>
      <!-- <div class="exit" @click="exitUser">
        <span class="userName">{{ userInfo.realName }}</span>
        <div class="userAvatar" :style="{
          backgroundImage: `url(${userInfo.avatar})`,
          backgroundReapeat: 'no-reapeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          borderRadius: '50%',
        }"></div>
      </div> -->
      <el-dropdown class="avatar" v-if="userInfo.realName">
        <div class="avatar">
          <span class="name">{{ userInfo.realName }}</span>
          <img class="avatar-pic" :src="userInfo.avatar" />
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <!-- <el-dropdown-item >
              <div class="header-avatar-item" @click="resetPass">
                <img class="gray" src="" />
                <img class="white" src="" />
                <span>修改密码</span>
              </div>
            </el-dropdown-item> -->
            <el-dropdown-item>
              <div class="header-avatar-item" @click="exitUser">
                <img class="gray" src="" />
                <img class="white" src="" />
                <span>退出登录</span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>

    <!-- 弹出框位置 -->
    <div v-if="signal == 2" :class="`shadowBox`" style="justify-content: center;text-align: -webkit-center;">
      <moudle-modal
        @myclick="handleCover"
        v-if="contentDetail !== null"
        :contentDetail="contentDetail"
      ></moudle-modal>
      <div style="color:black;height:586px;width:520px;background:white">
        <div class="close_button" @click="handleClose">X</div>
        <div class="user_logo">
          <img src="https://step-edu.oss-cn-beijing.aliyuncs.com/home-logo.png" alt="">
        </div>
        <el-form
          :model="resetForm"
          :rules="resetFormRules"
          ref="resetFormRef"
          status-icon
          label-width="100px"
          style="margin-top:30px"
        >
          <el-form-item label="旧密码：" prop="oldPassword">
            <el-input type="password" size="large" v-model="resetForm.oldPassword" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item label="新密码：" prop="password">
            <el-input type="password" size="large" v-model="resetForm.password" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item label="确认密码：" prop="newpassword">
            <el-input type="password" size="large" v-model="resetForm.newpassword" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm(resetFormRef)" style="height:40px;width:352px">确认修改</el-button>
          </el-form-item>
        </el-form>
      </div>

    </div>


    <div v-if="signal == 1" :class="`shadowBox`">
      <moudle-modal
        @myclick="handleCover"
        v-if="contentDetail !== null"
        :contentDetail="contentDetail"
      ></moudle-modal>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, ref, toRefs, watch } from "vue";
import { useStore } from 'vuex'

import MoudleModal from "@/components/moudleModal.vue";
import { request } from "@/api/request";
import { useRouter } from "vue-router";

export default defineComponent({
  props: ["slider", "sencond", "third"],
  components: { MoudleModal },
  setup(props) {
    const sliderChange = props;
    const store = useStore();
    const router = useRouter();
    const password = ref('');
    const oldPassword = ref('');
    const passwordAgain = ref('');
    const resetFormRef = ref(null);

    const resetForm = ref ({
      //传给后台所需要的参数
        newpassword: "",
        password: "",
        oldPassword: ""//此处只是后台需要的字段而已，如果前期有公用cookie里面有获取并且保存过，现在需要另外调用进来，具体的获取方法就看个人了
    });

    var userInfo = store._state.data.userInfo;
    var knowledgePointId = store._state.data.knowledgePointId;
    console.log("当前用户的know为" + knowledgePointId)
    const state = reactive({
      currentIndex: 1,
      sliderChange,
      userInfo,
      knowledgePointId
    });
    const validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入新密码"));
      } else {
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== resetForm.value.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    const resetFormRules = ref({
      oldPassword: [
          { required: true, message: "请输入旧密码", trigger: 'change' }
      ],
      password: [
          { required: true, validator: validatePass, trigger: 'change' }
      ],
      newpassword: [
        { required: true, validator: validatePass2, trigger: "change" }
      ]
    });
    const submitForm = async () =>  {
      console.log(resetFormRef.value);
      (resetFormRef.value)?.validate((valid) => {
          if (valid) {
             console.log('验证通过');
             request('/iredmanual/changingPassword',{password : resetForm.value.password},'POST').then(res =>{
              console.log(res)
              document.cookie = `token=${''}`
              sessionStorage.removeItem('token')
              localStorage.removeItem('progress')
              router.push('/');
            })
          }
        });
        //  this.$refs[resetFormRef].validate(async (valid) => {
      //   if (valid) {
      //     // const result = await CommonService.resetPasswordForUser(
      //     //   this.ruleForm
      //     // );
      //     // console.log("==================", result);
      //     // store.commit("removeToken");
      //     // store.commit("user/clearUser");
      //     // router.push("/login");
      //   } else {
      //     console.log("error submit!!");
      //     return false;
      //   }
      // });
    };
    const exitUser = function(){
      document.cookie = `token=${''}`
      sessionStorage.removeItem('token')
      localStorage.removeItem('progress')
      //20221206修改退出不清理后台token
     /* request('/iredmanual/logout','','GET').then(res =>{
              console.log(res)
            }) */
      router.push('/');
    }

    const getContent = function(item){
      console.log(item);
      this.keyWords = "";
      this.newListData = [];
      if(item.id <= knowledgePointId){
        request(`/iredmanual/subDetail?id=${item.id}&sync=${item.sync}`, "", "GET").then((data) => {
          console.log(data);
          this.contentDetail = data.result;
          // state.mainContent.contentDetail.sync = data[0].snyc;
          // state.mainContent.contentDetail.content = data[0].mainContent;
          // state.mainContent.contentDetail.id = data[0].id;
          // state.mainContent.contentDetail.title = data[0].title;
          // state.mainContent.contentDetail.sencondTitle = data[0].text;
        });
      } else {
        alert('课程未解锁')
      }

    }


    watch(state.sliderChange, () => {
      // console.log('正在监听滚动条变化: ' + state.sliderChange.slider)
      if ( state.sliderChange.slider < 0 && state.sliderChange.slider > state.sliderChange.sencond ) {
        // console.log('步步为营界面');
        state.currentIndex = 1;
      } else if ( state.sliderChange.slider < state.sliderChange.sencond && state.sliderChange.slider > state.sliderChange.third ) {
        // console.log('学富五车界面');
        state.currentIndex = 2;
      } else if (state.sliderChange.slider < state.sliderChange.third) {
        // console.log("有章有法界面");
        state.currentIndex = 3;
      }
    });

    onMounted( () =>{

        state.userInfo = store._state.data.userInfo;
        console.log(state.userInfo.avatar)
        window.addEventListener("resize", () => {
            // if()
            state.currentIndex = 1;
        });


    });
    const resetPass = function(){
      console.log('修改密码');
      this.signal = 2;
    };


    return {
      ...toRefs(state),
      exitUser,
      resetPass,
      getContent,
      submitForm,
      password,
      oldPassword,
      passwordAgain,
      resetForm,
      resetFormRules,
      resetFormRef,
    };
  },
  methods: {

    showCert () {
      this.$emit("showCert", true);
    },
    jumpContent(id) {
      console.log(id);
      this.currentIndex = id;
      this.$emit("jumpContent", id);
    },
    getRelated() {
      // console.log(this.newListData)
      this.newListData = [];

      clearTimeout(timeout);
      console.log(this.keyWords);
      // console.log(item.data)

      var timeout = setTimeout(() => {
        this.newListData = [];

        request(`/iredmanual/queryList?name=${this.keyWords}`, "", "GET").then(res =>{
          console.log('查询接口' + res);
          this.newListData = res.result;
        })

        this.data.filter((item) => {
          if (item.indexOf(this.keyWords) !== -1 && this.keyWords !== "") {
            this.newListData.push(item);
          }
        });
      }, 300);
    },
    changeHeader() {
      this.signal = 1;
      console.log("哎呀,我被选中啊");
    },
    handleClose() {
      this.signal = 0;
    },
    handleCover() {
      this.signal = 0;
      this.contentDetail = null;
    },
  },
  data() {
    return {
      keyWords: "",
      data: [
        "目标群体消费观",
        "年龄层需求划分",
        "用户喜好及产品匹配分析",
        "大家一起来胡乱分析",
        "群乱暴动",
        "宁有",
        "用户啥也不会",
      ],
      newListData: [],
      signal: Number,
      contentDetail: null,
    };
  },
});
</script>

<style lang="less" scoped>
.avatar {
    display: flex;
    align-items: center;
    cursor:pointer;
    .avatar-pic {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    .name {
      width: 49px;
      margin-right: 10px;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
    }
  }
  .header-avatar-item {
  display: flex;
  align-items: center;
  img {
    margin-right: 6px;
  }
}
.el-dropdown-menu__item {
  margin: 0;

  .white {
    display: none;
  }
  &:hover {
    background-color: #ed263d;
    color: #ffffff;

    .white {
      display: inline-block;
    }
    .gray {
      display: none;
    }
  }
}
.header {
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  div {
    height: 40px;
  }
  .logo {
    padding: 0 40px;
    width: 165px;
    background: url(https://step-edu.oss-cn-beijing.aliyuncs.com/home-logo.png) no-repeat center;
    background-size: contain;
  }
  .titleSmall {
    font-size: 16px;
    line-height: 21px;
    height: 22px;
    font-weight: 300;
  }
  .nav {
    width: 252px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      font-size: 16px;
      line-height: 40px;
      cursor: pointer;
    }
    .moudle {
      font-weight: lighter;
    }
    .active {
      color: #5fccb6;
      position: relative;
      font-weight: lighter;
      .textde {
        display: inline-block;
        position: absolute;
        height: 2px;
        width: 15px;
        background: #5fccb6;
        bottom: 8px;
        left: 25px;
        border-radius: 2px;
      }
    }
  }
  .close_button {
    width: 30px;
    height: 30px !important;
    font-size: 15px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    background: white;;
    float: right;
    margin-right: 20px;
    margin-top: 20px;
  }
  .user_logo {
    height: 64px !important;
    width: 246px;
    margin-top: 50px;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .el-input {
    width: 352px;
    height: 40px;
  }
  .el-input__inner {
    height: 40px;
    line-height: 40px;
  }
  .el-form-item {
    margin-bottom: 40px;
  }

  .input_bg {
    margin: 10px  40px;
    height: auto !important;
    .input {
      margin-top: 40px;
      display: inline-grid;
      height: auto !important;
      label {
        text-align: left;
        margin-bottom: 10px;
      }
    }
  }

  .navSearch {
    // color: black;
    flex: 1;
    justify-content: space-evenly;
    div {
      margin-right: -242px;
    }
    div.last_moudle {
      margin-right: 0px;
    }
  }
  .title {
    flex: 1;
    height: 42px;
    background: url(../../assets/header/titleBack.png) no-repeat center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    word-spacing: 2px;
    font-weight: lighter;
    padding: 0 15px;
    font-family: "Alibaba PuHuiTi-Regular";
    span {
      font-size: 12px;
      color: #5fccb6;
      font-weight: normal;
    }
  }
  .titleSearch {
    display: none;
  }
  .search {
    width: 200px;
    margin-right: 15px;
    justify-self: flex-end;
    display: flex;
    flex-direction: row;
    input {
      width: 141px;
      padding-left: 15px;
      font-size: 12px;
      color: white;
      font-family: "PingFang SC-Regular";
      border: none;
      background: none;
      outline: none;
      background: url("../../assets/header/Rectangle.png");
      &::-webkit-input-placeholder {
        color: #CDCDCD;
        // color: #999;
      }
    }
    img {
      position: absolute;
      right: 165px;
      top: 75px;
    }
    .searchButton {
      width: 54px;
      cursor: pointer;
      background: url("../../assets/header/search.png") no-repeat center;
    }
    .searchResult {
      width: 378px;
      min-height: 420px;
      height: auto;
      backdrop-filter: blur(10px) brightness(40%);
      background: rgba(124, 124, 124, 0.3);
      padding: 15px;
      position: fixed;
      top: 100px;
      z-index: 10;
      right: 110px;
      border-radius: 12px;
      ::after{
        content: ".";
        visibility: hidden;
        display: block;
        font-size: 0;
        clear: both;
        height: 0;
      }
      .result_menu {
        float: left;
        border: 1px solid #fff;
        border-radius: 24px;
        padding: 0px 10px;
        height: 40px;
        line-height: 40px;
        margin-right: 10px;
        margin-bottom: 10px;
        font-size: 16px;
        cursor: pointer;
      }
      .result_menu_active{
        border: 1px solid #FFDA26;
        color: #FFDA26;
      }
      .close_button {
        width: 30px;
        height: 30px;
        font-size: 15px;
        line-height: 30px;
        text-align: center;
        border-radius: 50%;
        background: #000;
        position: absolute;
        right: 15px;
        cursor: pointer;
      }
    }
  }

  .user {
    width: 157px;
    display: flex;
    padding-right: 40px;
    .cert {
      width: 45px;
      margin-right: 15px;
      background: url(../../assets/header/cert.png) no-repeat center;
    }
    .exit {
      display: flex;
      // width: 45px;
      width: 128px;
      align-items: center;
      // background: url(../../assets/header/exit.png) no-repeat center;
      cursor: pointer;
      .userName{
        width: 49px;
        height: 23px;
        font-size: 16px;
        margin-right: 10px;
      }
      .userAvatar{
        width: 50px;
        height: 50px;
      }
    }
  }
  .shadowBox {
    width: 100%;
    height: calc(100vh - 100px);
    position: fixed;
    top: 100px;
    left: 0;
    backdrop-filter: blur(10px) brightness(40%);
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
  }
}
@media only screen and(max-width:1466px) {
  .header {
    .nav {
      div {
        font-size: 14px;
      }
    }
    .title {
      font-size: 16px;
    }
    .search {
      .searchResult {
        top: 70px;
      }
    }
    .navSearch {
      justify-content: space-around;
    }
    .shadowBox {
      height: calc(100vh - 70px);
      top: 70px;
    }
  }
}
</style>
