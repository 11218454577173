<template>
  <div id="login">
    <div class="mainBox">
      <div class="people"></div>

      <div
          class="loginBox"
          :style="[{ paddingTop: current == 3 ? '70px' : '' }]"
      >
        <span class="logo"></span>
        <div class="title">{{systemName}}<span>·翻翻查·</span></div>
        <div class="sub_title">{{subheading}}</div>
        <div class="nav">
          <!--                     <div :class="`navText ${current == 1 && 'active'}`" @click="handleClick(1)">账号密码登录</div>-->
          <div
              :class="`navText ${current == 2 && 'active'}`"
              @click="handleClick(2)"
          >
            登录
          </div>
          <div
              :class="`navText ${current == 3 && 'active'}`"
              @click="handleClick(3)"
          >
            许可证注册
          </div>
        </div>

        <div class="inputBox">
          <div class="license" v-if="current == 3">
            <div class="inputTitle">许可证</div>
            <input
                :placeholder="place.licenseNo"
                type="text"
                autocomplete="off"
                maxlength="16"
                v-model="licenseNo"
            />
          </div>
          <div class="license" v-if="current == 3">
            <div class="inputTitle">姓名</div>
            <input
                :placeholder="place.names"
                type="text"
                autocomplete="off"
                maxlength="15"
                v-model="names"
            />
          </div>
          <div class="accout">
            <div class="inputTitle">
              {{ current == 2 || current == 3 ? "手机号" : "账号" }}
            </div>
            <input
                :placeholder="place.account"
                type="text"
                maxlength="11"
                autocomplete="off"
                v-model="accountInput"
            />
          </div>
          <div class="password">
            <div class="inputTitle">
              {{ current == 2 || current == 3 ? "验证码" : "密码" }}
            </div>
            <input
                :placeholder="place.atwill"
                :type="current == 2 || current == 3 ? 'text' : 'password'"
                autocomplete="off"
                maxlength="15"
                v-model="passwordInput"
            />
            <div
                v-if="current == 2 || current == 3"
                class="getSMS"
                @click="getSMS"
            >
              {{ sendSmsBtnText }}
            </div>
          </div>
        </div>

        <div @click="login" class="loginButton">
          {{ current == 2 ? "登录" : "注册并登录" }}
        </div>
        <div class="deBall"></div>

        <div class="message"></div>
      </div>
    </div>

    <div class="deBall leftTop"></div>

    <div v-if="isChrome == null" class="bottom_text">
      推荐使用Google Chrome浏览器访问本网站,以避免兼容性问题。
    </div>
    <Toast ref="toastRef"/>
  </div>
</template>

<script>
import {defineComponent, reactive, ref, toRefs} from "vue";
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import {request} from "@/api/request";
import Toast from "@/components/toast/Toast";
import qs from 'qs'

export default defineComponent({
  components: {Toast},
  setup() {
    const systemName = ref("");
    const subheading = ref("");
    if(location.origin){
        request(
            "/iredEdu/platform/findPlatformByWebUrl",
            {"webUrl":location.origin},
            "POST"
        ).then((res) => {
          if(res.result !== null){
            systemName.value = res.result.name
            subheading.value = res.result.subheading
          }else{
            systemName.value = "STEP直播电商智慧手册"
            subheading.value = "【直播电商智慧系统】"
          }
        }).catch(() => {
          systemName.value = "STEP直播电商智慧手册"
          subheading.value = "【直播电商智慧系统】"
        });
      }else{
        systemName.value = "STEP直播电商智慧手册"
        subheading.value = "【直播电商智慧系统】"
      }
    const urlParams = qs.parse(location.search.replace('?', ''));
      if (urlParams.token) {
        request(
            "/iredmanual/noAuth/loginByToken",
            {"token": urlParams.token},
            "POST"
        ).then((res) => {
          res.code == 200
              ? loginSuccess(res.result)
              : showMsgModal(res.message);
        });
      }

    let current = 2;
    let sendSmsBtnText = "获取验证码";

    const toastRef = ref();

    let place = {
      // account: '请输入账号',
      // atwill: '请输入密码'
      account: "请输入手机号",
      licenseNo: "请输入许可证编号",
      atwill: "请输入验证码",
      names: "请输入姓名",
    };
    var accountInput = "";
    var passwordInput = "";
    var isChrome = navigator.userAgent.toLowerCase().match("chrome");
    console.log(isChrome);
    if (isChrome == null) {
      alert("此系统不兼容当前浏览器,请使用chrome内核浏览器打开!");
    }
    const router = useRouter();
    const store = useStore();

    const state = reactive({
      current,
      place,
      accountInput,
      passwordInput,
      licenseNo: null,
      names: null,
      timeDown: 0,
      timer: null,
      sendSmsBtnText,
      isChrome,
    });

    const handleClick = function (item) {
      console.log(item);
      if (item == 1) {
        state.accountInput = "";
        state.passwordInput = "";
        state.place.account = "请输入账号";
        state.place.atwill = "请输入密码";
      } else {
        state.accountInput = "";
        state.passwordInput = "";
        state.place.account = "请输入手机号";
        state.place.atwill = "请输入验证码";
      }
      state.current = item;
    };

    const login = function () {
      console.log(
          "account: " +
          state.accountInput +
          "  password: " +
          state.passwordInput +
          " licenseNo:" +
          state.licenseNo
      );

      if (state.current == 1) {
        request(
            "/iredmanual/noAuth/doLogin",
            {
              userName: state.accountInput,
              password: state.passwordInput,
            },
            "POST"
        ).then((res) => {
          console.log(res);
          res.code == 200
              ? loginSuccess(res.result)
              : showMsgModal(res.message);
        });
      } else if (state.current == 2) {
        request(
            "/iredmanual/noAuth/phoneLogin",
            {
              mobile: state.accountInput,
              captcha: state.passwordInput,
			  webUrl : window.location.host
            },
            "POST"
        ).then((res) => {
          res.code == 200
              ? loginSuccess(res.result)
              : showMsgModal(res.message);
        });
      } else if (state.current == 3) {
        request(
            "/iredmanual/noAuth/register",
            {
              mobile: state.accountInput,
              captcha: state.passwordInput,
              licenseNo: state.licenseNo,
              names: state.names,
            },
            "POST"
        ).then((res) => {
          if (res.code == 200) {
            state.current = 2;
            showMsgModal("注册成功");
            loginSuccess(res.result)
          } else {
            showMsgModal(res.message);
          }
        });
      }
    };

    const showMsgModal = (msg) => {
      toastRef.value.show(msg);
    };

    const loginSuccess = function (item) {
      sessionStorage.setItem("token", item.token);
      store.dispatch("userInfoChange", item);

      request("/iredmanual/getSchedule", "", "GET").then((res) => {
        store.dispatch("progressChange", res.result);
        store.dispatch("nodeChange", res.result.split("/")[0]);
        store.dispatch("knowledgeChange", res.result.split("/")[1]);
        store.dispatch("browsingChange", res.result.split("/")[2]);
      });
      setTimeout(() => {
        router.push("/home");
      });
    };

    const getSMS = function () {
      if (state.timeDown != 0) {
        return;
      }
      if (state.accountInput && state.accountInput.length != 11) {
        return;
      }
      if (state.current === 3) {
        request(
            "/iredmanual/noAuth/register/sms",
            {
              mobile: state.accountInput,
            },
            "POST"
        ).then((res) => {
          res.code == 200
              ? loginSuccess(res.result)
              : showMsgModal(res.message);
          if (res.success) {
            start();
          }
        });
      } else {
        request(
            "/iredmanual/noAuth/sms",
            {
              mobile: state.accountInput,
            },
            "POST"
        ).then((res) => {
          res.code == 200
              ? loginSuccess(res.result)
              : showMsgModal(res.message);
          if (res.success) {
            start();
          }
        });
      }
    };

    /**
     * 开始倒计时
     */
    const start = function () {
      clear();
      state.timeDown = 60;
      state.timer = setInterval(() => {
        state.timeDown--;
        console.log(sendSmsBtnText);
        state.sendSmsBtnText = "重新发送(" + state.timeDown + ")";
        if (state.timeDown <= 0) {
          state.sendSmsBtnText = "重新发送";
          clear();
        }
      }, 1000);
    };

    /**
     * 清除倒计时
     */
    const clear = function () {
      if (state.timer) {
        clearInterval(state.timer);
      }
    };

    return {
      ...toRefs(state, sendSmsBtnText),
      handleClick,
      login,
      getSMS,
      systemName,
      subheading,
      toastRef,
    };
  },
});
</script>

<style lang="less" scoped>
#login {
  width: 100%;
  height: 100%;
  min-width: 1440px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../assets/login/group.png") no-repeat center;
  background-size: cover;
  backdrop-filter: blur(10px) opacity(10%);

  .mainBox {
    width: 1440px;
    height: 900px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .people {
      width: 650px;
      height: 650px;
      background: url("../../assets/login/paper.png") no-repeat center;
    }

    .loginBox {
      height: 650px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: white;
      font-size: 14px;
      position: relative;
      z-index: 1;

      .logo {
        width: 285px;
        height: 75px;
        // width: 210px;
        // height: 60px;
        position: absolute;
        top: -40px;
        background: url(https://step-edu.oss-cn-beijing.aliyuncs.com/home-logo.png) no-repeat center;
        background-size: contain;
        // transform: scale(1.2);
      }

      .title {
        font-size: 34px;
        font-weight: bolder;
        margin-bottom: 20px;

        span {
          font-size: 20px;
          font-weight: normal;
          color: #5fccb6;
        }
      }
      .sub_title {
        text-align: left;
        margin-bottom: 25px;
        font-size: 29px;
        font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
        font-weight: 400;
        color: #FFFFFF;
        margin-left: -15px;
      }

      .nav {
        display: flex;
        margin-bottom: 40px;

        .navText {
          font-size: 16px;
          line-height: 20px;
          cursor: pointer;
          margin-right: 30px;
        }

        .active {
          font-size: 25px;
        }
      }

      .inputBox {
        margin-bottom: 63px;

        input {
          border: none;
          background: none;
          color: white;

          &:focus {
            outline: none;
            background: none;
          }

          &:-webkit-autofill {
            padding-left: 25px !important;
            border: none;
            border-bottom: 1px solid #fff;
            box-shadow: 0 0 0px 1000px #121212 inset !important;
            background: none !important;
            background-image: none !important;
            -webkit-text-fill-color: white;
          }
        }

        .license {
          margin-bottom: 40px;

          input {
            height: 40px;
            width: 400px;
            background: url("../../assets/login/license.png") no-repeat;
            background-position-y: center;
            padding-left: 25px;
            border-bottom: 1px solid #fff;
          }
        }

        .accout {
          margin-bottom: 40px;

          input {
            height: 40px;
            width: 400px;
            background: url("../../assets/login/phone.png") no-repeat;
            background-position-y: center;
            padding-left: 25px;
            border-bottom: 1px solid #fff;
          }
        }

        .password {
          position: relative;

          input {
            height: 40px;
            width: 400px;
            background: url("../../assets/login/password.png") no-repeat;
            background-position-y: center;
            padding-left: 25px;
            border-bottom: 1px solid #fff;
          }

          .getSMS {
            color: #ed263d;
            position: absolute;
            right: 15px;
            top: 29px;
            cursor: pointer;
          }
        }

        .inputTitle {
          text-align: left;
        }
      }

      .loginButton {
        width: 400px;
        height: 48px;
        background: #5fccb6;
        border-radius: 10px;
        color: white;
        font-size: 16px;
        line-height: 48px;
        align-self: center;
        cursor: pointer;
      }
    }
  }

  .deBall {
    width: 313px;
    height: 313px;
    border-radius: 50%;
    background-image: radial-gradient(rgba(95, 204, 182, 0.15),
    rgba(95, 204, 182, 0));
    position: absolute;
    filter: blur(40px);
    z-index: 0;
    right: -90px;
    top: -5px;
  }

  .leftTop {
    position: fixed;
    top: -130px;
    left: -130px;
  }

  .bottom_text {
    position: absolute;
    bottom: 30px;
    color: white;
  }
}

@media only screen and(max-width: 1466px) {
  .mainBox {
    transform: scale(0.8);
  }
}
</style>
