<template>
  <div class="home">
    <div class="home_top_nav">
      <header-view @jumpContent="useSlider" @showCert="showCert" :slider="slider" :sencond="sencondIndex"
                   :third="thirdIndex"></header-view>
    </div>

    <div
        class="home_cont_wrap"
        :style="{
        transform: `translateX(${slider}px)`,
      }">
      <div class="moudle_1">
        <Railway class="moudle1" :type="1" :currentStation="+railWayNoA"></Railway>
        <moudle-elements
            v-for="(item, index) in moudle1Element"
            :key="index"
            :elementAttr="item"
            @getContent="getAllContent"
        />
      </div>
      <div class="moudle_2">
        <Railway class="moudle2" :type="2" :currentStation="+railWayNoB"></Railway>
        <moudle-elements
            v-for="(item, index) in moudle2Element"
            :key="index"
            :elementAttr="item"
            @getContent="getAllContent"
        />
      </div>
      <div id="moudle3" class="moudle_3">
        <Railway class="moudle3" :type="3" :currentStation="+railWayNoC"></Railway>
        <moudle-elements
            v-for="(item, index) in moudle3Element"
            :key="index"
            :elementAttr="item"
            @getContent="getAllContent"
        />
      </div>
    </div>

    <div class="arrow">
      <div v-if="slider != 0" @click="turnLeft" class="leftArrow"></div>
      <div v-if="slider != maxSlider" @click="turnRight" class="rightArrow"></div>
    </div>

    <div v-if="coverIndex != 1" :class="`home_modal ${coverIndex == 1 && 'byebye'}`">
      <moudle-modal
          @myclick="handleCover"
          :contentDetail="contentDetail"
      ></moudle-modal>
    </div>

    <CerGetter v-if="showCer" ref="CerGetter"/>
  </div>
</template>

<script>
// @ is an alias to /src
import {defineComponent, nextTick, onMounted, reactive, ref, toRefs, watch} from "vue";
import {useStore} from 'vuex'

import MoudleElements from "@/components/moudleElements.vue";
import moudle1Element from "./moudle1Element";
import moudle2Element from "./moudle2Element";
import moudle3Element from "./moudle3Element";

import MoudleModal from "@/components/moudleModal.vue";
import HeaderView from "@/components/header/headerView.vue";
import Railway from "@/components/Railway/index.vue"
import CerGetter from "@/components/cer/Index.vue";

export default defineComponent({
  name: "Home",
  // Railway
  components: {CerGetter, MoudleElements, MoudleModal, HeaderView, Railway},
  setup() {
    const contentDetail = {
      title: "",
      sencondTitle: "",
      content: "",
      cover: "",
      type: 0,
      sync: "A-1",
      moveFun: false,
      videoDtoList: []
    };
    const store = useStore();
    console.log(store._state)
    const state = reactive({
      moudle1Element,
      moudle2Element,
      moudle3Element,
      railWayNoA: 0,
      railWayNoB: 0,
      railWayNoC: 0,
      contentDetail,
      showCer: false
    });

    const CerGetter = ref();

    const showCert = () => {

      if (store.state.knowledgePointId + '' !== '211') {
        alert("当前进度未完成")
        return;
      }

      state.showCer = true;

      nextTick(() => {
        CerGetter.value.init();
      })

    }

    watch(() => store._state.data.userNodeRecordId, () => {
      console.log('首页检测store中用户主节点发生变化')
      switch (store._state.data.userNodeRecordId.split('-')[0]) {
        case 'A':
          state.railWayNoA = store._state.data.userNodeRecordId.split('-')[1];
          break;
        case 'B':
          state.railWayNoA = 38;
          state.railWayNoB = store._state.data.userNodeRecordId.split('-')[1];
          break;
        case 'C':
          state.railWayNoA = 38;
          state.railWayNoB = 16;
          state.railWayNoC = store._state.data.userNodeRecordId.split('-')[1];
          break;
        default:
          state.railWayNoA = 38;
          state.railWayNoB = 16;
          state.railWayNoC = 34;
          break;
      }
    })

    onMounted(() => {
      console.log(store._state)
      setTimeout(() => {
        console.log('首页检测用户当前查看到节点' + store._state.data.userNodeRecordId)
        switch (store._state.data.userNodeRecordId.split('-')[0]) {
          case 'A':
            state.railWayNoA = store._state.data.userNodeRecordId.split('-')[1];
            break;
          case 'B':
            state.railWayNoA = 38;
            state.railWayNoB = store._state.data.userNodeRecordId.split('-')[1];
            break;
          case 'C':
            state.railWayNoA = 38;
            state.railWayNoB = 16;
            state.railWayNoC = store._state.data.userNodeRecordId.split('-')[1];
            break;
          default:
            state.railWayNoA = 38;
            state.railWayNoB = 16;
            state.railWayNoC = 34;
            break;
        }
        console.log('A' + state.railWayNoA + 'B' + state.railWayNoB + 'C' + state.railWayNoC)
      }, 1000)
    })

    return {
      ...toRefs(state),
      showCert,
      CerGetter
    };
  },
  mounted() {
    // window.addEventListener('mousemove',this.handleKeyDown)
    // window.addEventListener('mousedown', this.handleMouseDown)
    // console.log('主页获取的Local'+ localStorage.getItem('progress'))
    // console.log("窗口初始宽度" + window.innerWidth);

    if (window.innerWidth <= 1466) {
      this.sencondIndex = -1820;
      this.thirdIndex = -3000;
      this.maxSlider = -3422 - Math.floor((1466 - window.innerWidth));
    } else if (window.innerWidth >= 1466 && window.innerWidth <= 1920) {
      this.sencondIndex = -2750;
      this.thirdIndex = -4500;
      this.maxSlider = -5322;
    } else {
      this.sencondIndex = -2750;
      this.thirdIndex = -4500;
      this.maxSlider = -5322 + Math.floor((window.innerWidth - 1920));
    }


    window.addEventListener('resize', (item) => {
      this.slider = 0;
      //监听浏览器窗口大小改变
      if (item.target.innerWidth <= 1466) {
        this.sencondIndex = -1820;
        this.thirdIndex = -3000;

        this.maxSlider = -3422 - Math.floor((1466 - item.target.innerWidth));
        // console.log("此时窗口的宽度：" + item.target.innerWidth + "此时二点坐标: " + this.sencondIndex + "三点坐标 " + this.thirdIndex)
      } else if (window.innerWidth >= 1466 && window.innerWidth <= 1920) {
        this.sencondIndex = -2750;
        this.thirdIndex = -4500;
        this.maxSlider = -5322;
        // console.log("此时窗口的宽度：" + item.target.innerWidth + "此时二点坐标: " + this.sencondIndex + "三点坐标 " + this.thirdIndex)

      } else {
        this.sencondIndex = -2750;
        this.thirdIndex = -4500;
        this.maxSlider = -5322 + Math.floor((window.innerWidth - 1920));
      }
    });
  },
  data() {
    return {
      coverIndex: 1,
      slider: 0,
      downClick: 0,
      upClick: 0,
      sb_bkx: 0,
      endx: 0,
      startx: 0,
      is_moving: false,
      moveFun: false,
      sencondIndex: -2750,
      thirdIndex: -4500,
      maxSlider: -5322
    };
  },
  methods: {
    handleCover() {
      this.coverIndex = 1;
    },
    getAllContent(msg) {
      this.contentDetail = msg;
      this.coverIndex = msg.cover;
      // console.log(this.contentDetail);
    },
    turnLeft() {
      if (this.slider + 572.2 > 0) {
        this.slider = 0;
      } else {
        this.slider += 572.2;
      }
      console.log(this.slider);
    },
    turnRight() {
      // let moudle3 = document.getElementById("moudle3");
      // console.log("距离右边距离：" + (document.body.offsetWidth - moudle3.offsetLeft - moudle3.offsetWidth));
      // 大屏 572.2 5772
      if (this.slider - 572.2 < this.maxSlider) {
        this.slider = this.maxSlider;
      } else {
        this.slider -= 572.2;
      }
      // console.log(this.slider);
    },
    useSlider(msg) {
      switch (msg) {
        case 1:
          this.slider = 0;
          break;
        case 2:
          this.slider = this.sencondIndex;
          // this.slider = -2750;
          break;

        case 3:
          this.slider = this.thirdIndex;
          // this.slider = -4500;
          break;
      }
    },
    handleMouseDown(event) {
      this.moveFun = true;
      // console.log(event);
      this.startx = event.screenX;
      this.is_moving = true;
      // console.log("这是点击时的 slider: " + this.slider);
    },
    handleMouseMove(item) {
      this.endx = item.screenX - this.startx;
      if (this.is_moving) {
        if (this.slider > 0) {
          this.slider = 0;
        } else if (this.slider < -5722) {
          this.slider = -5722;
        } else {
          this.slider = this.slider + this.endx;
        }
      }
    },
    handleMouseUp() {
      this.moveFun = false;
      this.is_moving = false;
    },
    showMove() {
      // console.log("显示拖动框");
    }
  },
});
</script>

<style lang="less" scoped>
.home {
  background: rgba(0, 0, 0, 0.95);
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding-top: 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  overflow: auto;

  .home_top_nav {
    background: rgba(255, 255, 255, 0.06);
    filter: blur(undefinedpx);
    width: 100vw;
    height: 100px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
  }

  .home_cont_wrap {
    width: 100vw;
    height: 755px;
    margin-top: 18px;
    margin-left: 92px;
    transition: all 1s;
    position: relative;

    .moudle_1 {
      width: 2695px;
      // width: 100vw;
      height: 755px;
      // background-image: url(../assets/home/line-1.png);
      background-size: 100% 100%;
      background-position: top center;
      display: inline-block;
      position: absolute;
      left: 20px;
      z-index: 1;

      .moudle1 {
        width: 2842px;
        height: 903px;
        left: -119px;
        top: -139px;
      }
    }

    .moudle_2 {
      width: 1769px;
      height: 755px;
      // background-image: url(../assets/home/line-2.png);
      background-size: 100% 100%;
      background-position: top center;
      display: inline-block;
      position: absolute;
      left: 2689px;
      top: -3px;
      // transform: translateX(-10px);
      z-index: 3;

      .moudle2 {
        width: 1884px;
        height: 903px;
        top: -123px;
        left: -59px;
      }
    }

    .moudle_3 {
      // background-color: rgb(231, 143, 55, 0.5);
      width: 2573px;
      height: 755px;
      // background-image: url(../assets/home/line-3.png);
      background-size: 100% 100%;
      background-position: top center;
      display: inline-block;
      position: absolute;
      left: 4448px;
      top: -3px;
      overflow: hidden;
      // transform: translateX(-20px);
      z-index: 4;

      .moudle3 {
        width: 3128px;
        height: 902px;
        top: -116px;
        left: -244px;
      }
    }
  }

  .home_modal {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: calc(100vh - 55px);
    top: 55px;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px) brightness(40%);
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .byebye {
    display: none;
  }

  /* 设置滚动条的样式 */

  &::-webkit-scrollbar {
    width: 0px;
    height: 6px;
  }

  /* 滚动槽 */

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
  }

  &::-webkit-scrollbar-track:hover &::-webkit-scrollbar-thumb {
    background: rgba(233, 233, 233, 0.5);
  }

  /* 滚动条滑块 */

  &::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background: transparent;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    transition: all 0.3s;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(233, 233, 233, 0.5);
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(233, 233, 233, 0.5);
  }
}

.arrow {
  position: fixed;
  width: 100vw;
  height: 40px;
  top: 140px;

  .leftArrow {
    width: 40px;
    height: 100%;
    position: absolute;
    left: 40px;
    background: url("../assets/home/left_arrow.png") center no-repeat;
    background-size: contain;
    cursor: pointer;
  }

  .rightArrow {
    width: 40px;
    height: 100%;
    position: absolute;
    right: 40px;
    background: url("../assets/home/right_arrow.png") center no-repeat;
    background-size: contain;
    cursor: pointer;
  }
}

@media only screen and(max-width: 1466px) {
  .home {
    display: flex;
    height: 100%;
    padding-top: 55px;

    .home_top_nav {
      height: 55px;
      font-size: 12px;
    }

    .home_cont_wrap {
      flex: 1;
      transform: scale(0.68);
      margin-top: -25px;

      .moudle_1 {
        left: -470px;
        transform: scale(0.68);

        .moudle1 {
          // transform: scale(0.68);
          // left: -279px;
        }
      }

      .moudle_2 {
        left: 1491px;
        top: -2px;
        transform: scale(0.68);
      }

      .moudle_3 {
        left: 2558px;
        top: -1px;
        transform: scale(0.68);
      }
    }
  }

  .arrow {
    top: 95px;
  }
}
</style>
