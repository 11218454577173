import moudle1TopImg from "@/assets/home/moudle_3_top_img.png";

import buttonHowBlack from "@/assets/moudle3/button_how_black.png";
import buttonPeaBlack from "@/assets/moudle3/button_pea_black.png";
import buttonSearchBlack from "@/assets/moudle3/button_search_black.png";
import buttonVideoBlack from "@/assets/moudle3/button_video_black.png";

import buttonHowWhite from "@/assets/moudle3/button_how.png";
import buttonPeaWhite from "@/assets/moudle3/button_pea.png";
import buttonSearchWhite from "@/assets/moudle3/button_search.png";
import buttonVideoWhite from "@/assets/moudle3/button_video.png";

import creama from "@/assets/moudle3/Camera.png";
import film from "@/assets/moudle3/film.png";
import freepik from "@/assets/moudle3/freepik.png";
import groupBack2 from "@/assets/moudle3/Group_back2.png";
import groupGear from "@/assets/moudle3/Group_gear.png";
import groupHouse1 from "@/assets/moudle3/Group_house1.png";
import groupHome1 from "@/assets/moudle3/GroupHome1.png";
import judge from "@/assets/moudle3/Judge.png";
import rafiki from "@/assets/moudle3/rafiki.png";
import sheets from "@/assets/moudle3/sheets.png";

import titleBack from "@/assets/moudle3/titleBack3.png";
import steel from "@/assets/home/steel.png";

// import arrowUp from '@/assets/moudleArrow/arrow_up.png'
import arrowRight from "@/assets/moudleArrow/arrow_right.png";
// import arrowLeft from '@/assets/moudleArrow/arrow_left.png'
import arrowDown from "@/assets/moudleArrow/arrow_down.png";

import blue from "@/assets/moudleArrow/blue.png";

export default [{
        type: "img",
        src: moudle1TopImg,
        style: {
            position: "absolute",
            top: "-28px",
            left: "4px",
            width: "686px",
            height: "249px",
        },
    },
    // pasterpaper
    {
        type: "img",
        src: creama,
        style: {
            position: "absolute",
            top: "288px",
            left: "1620px",
        },
    },
    {
        type: "img",
        src: steel,
        style: {
            position: "absolute",
            top: "601px",
            right: "642px",
        },
    },
    {
        type: "img",
        src: steel,
        style: {
            position: "absolute",
            top: "601px",
            right: "370px",
        },
    },
    {
        type: "img",
        src: steel,
        style: {
            position: "absolute",
            top: "601px",
            right: "143px",
        },
    },
    {
        type: "img",
        src: film,
        style: {
            position: "absolute",
            top: "145px",
            left: "1874px",
        },
    },
    {
        type: "img",
        src: freepik,
        style: {
            position: "absolute",
            top: "433px",
            left: "194px",
        },
    },
    {
        type: "img",
        src: groupBack2,
        style: {
            position: "absolute",
            bottom: "-27px",
            left: "-2px",
        },
    },
    {
        type: "img",
        src: groupGear,
        style: {
            position: "absolute",
            bottom: "470px",
            left: "2025px",
            zIndex: "-999px",
        },
    },
    {
        type: "img",
        src: groupHouse1,
        style: {
            position: "absolute",
            bottom: "336px",
            left: "903px",
        },
    },
    {
        type: "img",
        src: groupHome1,
        style: {
            position: "absolute",
            bottom: "32px",
            left: "389px",
        },
    },
    {
        type: "img",
        src: judge,
        style: {
            position: "absolute",
            top: "320px",
            left: "2066px",
        },
    },
    {
        type: "img",
        src: rafiki,
        style: {
            position: "absolute",
            top: "256px",
            left: "159px",
        },
    },
    {
        type: "img",
        src: sheets,
        style: {
            position: "absolute",
            top: "114px",
            left: "1116px",
        },
    },

    {
        type: "img",
        src: titleBack,
        style: {
            position: "absolute",
            top: "445px",
            left: "2480px",
        },
    },
    {
        type: "img",
        src: titleBack,
        style: {
            position: "absolute",
            top: "571px",
            left: "1651px",
        },
    },
    {
        type: "img",
        src: titleBack,
        style: {
            position: "absolute",
            top: "106px",
            left: "1716px",
        },
    },
    {
        type: "img",
        src: titleBack,
        style: {
            position: "absolute",
            top: "590px",
            left: "1209px",
        },
    },
    {
        type: "img",
        src: titleBack,
        style: {
            position: "absolute",
            top: "276px",
            left: "1124px",
        },
    },
    {
        type: "img",
        src: titleBack,
        style: {
            position: "absolute",
            top: "303px",
            left: "42px",
        },
    },

    // Tip
    {
        type: "tip",
        text: "广告法",
        style: {
            position: "absolute",
            top: "324px",
            left: "33px",
            width: "87px",
            height: "53px",
            color: "black",
            background: "none",
        },
    },
    {
        type: "tip",
        text: "电子商务法",
        style: {
            position: "absolute",
            top: "296px",
            left: "1115px",
            width: "87px",
            height: "53px",
            color: "black",
            background: "none",
        },
    },
    {
        type: "tip",
        text: "网络信息内容生态治理规定",
        style: {
            position: "absolute",
            top: "600px",
            left: "1196px",
            width: "87px",
            height: "53px",
            color: "black",
            background: "none",
        },
    },
    {
        type: "tip",
        text: "网络短视频平台管理规范",
        style: {
            position: "absolute",
            top: "116px",
            left: "1705px",
            width: "87px",
            height: "53px",
            color: "black",
            background: "none",
        },
    },
    {
        type: "tip",
        text: "电子商务平台产品信息展示要求",
        style: {
            position: "absolute",
            top: "572px",
            left: "1643px",
            width: "87px",
            height: "53px",
            color: "black",
            background: "none",
        },
    },
    {
        type: "tip",
        text: "相关行业及平台规范",
        style: {
            position: "absolute",
            top: "457px",
            left: "2471px",
            width: "87px",
            height: "53px",
            color: "black",
            background: "none",
        },
    },

    // menuTip
    {
        type: "menuTip",
        id: 1,
        title: "《广告法》与电商直播员",
        sencondTitle: "《广告法》与电商直播员",
        text: "《广告法》与电商直播员",
        sync: "C-1",
        src: buttonHowBlack,
        srca: buttonHowWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "325px",
            left: "132px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 2,
        title: "《广告法》中的“素材”的含义与范围",
        sencondTitle: "《广告法》中的“素材”的含义与范围",
        text: "《广告法》中的“素材”的含义与范围",
        sync: "C-2",
        src: buttonHowBlack,
        srca: buttonHowWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "190px",
            left: "132px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 3,
        title: "《广告法》中的引证内容",
        sencondTitle: "《广告法》中的引证内容",
        text: "《广告法》中的引证内容",
        sync: "C-3",
        src: buttonHowBlack,
        srca: buttonHowWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "120px",
            left: "132px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 4,
        title: "《广告法》中的”虚假广告“",
        sencondTitle: "《广告法》中的”虚假广告“",
        text: "《广告法》中的”虚假广告“",
        sync: "C-4",
        src: buttonHowBlack,
        srca: buttonHowWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "60px",
            left: "132px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 5,
        title: "典型”违法违规“案例-虚假广告",
        sencondTitle: "典型”违法违规“案例-虚假广告",
        text: "典型”违法违规“案例-虚假广告",
        sync: "C-5",
        src: buttonSearchBlack,
        srca: buttonSearchWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "62px",
            left: "536px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 6,
        title: "《广告法》中的违规用语",
        sencondTitle: "《广告法》中的违规用语",
        text: "《广告法》中的违规用语",
        sync: "C-6",
        src: buttonHowBlack,
        srca: buttonHowWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "128px",
            left: "585px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 7,
        title: "典型”违法违规“案例-违规用语",
        sencondTitle: "典型”违法违规“案例-违规用语",
        text: "典型”违法违规“案例-违规用语",
        sync: "C-7",
        src: buttonSearchBlack,
        srca: buttonSearchWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "198px",
            left: "538px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 8,
        title: "《广告法》的重点法规",
        sencondTitle: "《广告法》的重点法规",
        text: "《广告法》的重点法规",
        sync: "C-8",
        src: buttonHowBlack,
        srca: buttonHowWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "269px",
            left: "600px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 9,
        title: "典型”违法违规“案例-违规内容",
        sencondTitle: "典型”违法违规“案例-违规内容",
        text: "典型”违法违规“案例-违规内容",
        sync: "C-9",
        src: buttonSearchBlack,
        srca: buttonSearchWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "338px",
            left: "540px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 10,
        title: "《广告法》中的不得出现的情形",
        sencondTitle: "《广告法》中的不得出现的情形",
        text: "《广告法》中的不得出现的情形",
        sync: "C-10",
        src: buttonHowBlack,
        srca: buttonHowWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "411px",
            left: "545px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 11,
        title: "典型”违法违规“案例-违规情形",
        sencondTitle: "典型”违法违规“案例-违规情形",
        text: "典型”违法违规“案例-违规情形",
        sync: "C-11",
        src: buttonSearchBlack,
        srca: buttonSearchWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "508px",
            left: "881px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 12,
        title: "《电子商务法》与电商直播员",
        sencondTitle: "《电子商务法》与电商直播员",
        text: "《电子商务法》与电商直播员",
        sync: "C-12",
        src: buttonHowBlack,
        srca: buttonHowWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "295px",
            left: "923px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 13,
        title: "《电子商务法》中经营者的责任与义务",
        sencondTitle: "《电子商务法》中经营者的责任与义务",
        text: "《电子商务法》中经营者的责任与义务",
        sync: "C-13",
        src: buttonHowBlack,
        srca: buttonHowWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "224px",
            left: "867px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 14,
        title: "《电子商务法》的重点法规",
        sencondTitle: "《电子商务法》的重点法规",
        text: "《电子商务法》的重点法规",
        sync: "C-14",
        src: buttonHowBlack,
        srca: buttonHowWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "153px",
            left: "936px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 15,
        title: "典型”违法违规“案例",
        sencondTitle: "典型”违法违规“案例",
        text: "典型”违法违规“案例",
        sync: "C-15",
        src: buttonSearchBlack,
        srca: buttonSearchWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "87px",
            left: "963px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 16,
        title: "《治理规定》与电商直播员",
        sencondTitle: "《治理规定》与电商直播员",
        text: "《治理规定》与电商直播员",
        sync: "C-16",
        src: buttonHowBlack,
        srca: buttonHowWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "153px",
            left: "1346px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 17,
        title: "《治理规定》中的不良信息",
        sencondTitle: "《治理规定》中的不良信息",
        text: "《治理规定》中的不良信息",
        sync: "C-17",
        src: buttonHowBlack,
        srca: buttonHowWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "222px",
            left: "1346px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 18,
        title: "《治理规定》中的禁止情形",
        sencondTitle: "《治理规定》中的禁止情形",
        text: "《治理规定》中的禁止情形",
        sync: "C-18",
        src: buttonHowBlack,
        srca: buttonHowWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "293px",
            left: "1346px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 19,
        title: "典型”违法违规“案例",
        sencondTitle: "典型”违法违规“案例",
        text: "典型”违法违规“案例",
        sync: "C-19",
        src: buttonSearchBlack,
        srca: buttonSearchWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "363px",
            left: "1346px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 20,
        title: "《治理规定》中的鼓励情形",
        sencondTitle: "《治理规定》中的鼓励情形",
        text: "《治理规定》中的鼓励情形",
        sync: "C-20",
        src: buttonHowBlack,
        srca: buttonHowWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "433px",
            left: "1346px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 21,
        title: "典型”正向“案例",
        sencondTitle: "典型”正向“案例",
        text: "典型”正向“案例",
        sync: "C-21",
        src: buttonSearchBlack,
        srca: buttonSearchWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "503px",
            left: "1346px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 22,
        title: "《管理规范》与电商直播员",
        sencondTitle: "《管理规范》与电商直播员",
        text: "《管理规范》与电商直播员",
        sync: "C-22",
        src: buttonHowBlack,
        srca: buttonHowWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "460px",
            left: "1758px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 23,
        title: "《管理规范》之账户管理规范",
        sencondTitle: "《管理规范》之账户管理规范",
        text: "《管理规范》之账户管理规范",
        sync: "C-23",
        src: buttonHowBlack,
        srca: buttonHowWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "390px",
            left: "1758px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 24,
        title: "《管理规范》之内容管理规范",
        sencondTitle: "《管理规范》之内容管理规范",
        text: "《管理规范》之内容管理规范",
        sync: "C-24",
        src: buttonHowBlack,
        srca: buttonHowWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "320px",
            left: "1758px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 25,
        title: "《管理规范》的重点规定",
        sencondTitle: "《管理规范》的重点规定",
        text: "《管理规范》的重点规定",
        sync: "C-25",
        src: buttonHowBlack,
        srca: buttonHowWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "250px",
            left: "1758px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 26,
        title: "《展示要求》与电商直播员",
        sencondTitle: "《展示要求》与电商直播员",
        text: "《展示要求》与电商直播员",
        sync: "C-26",
        src: buttonHowBlack,
        srca: buttonHowWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "109px",
            left: "1835px",
            width: "110px",
            height: "40px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 27,
        title: "《展示要求》中的信息内容与要求",
        sencondTitle: "《展示要求》中的信息内容与要求",
        text: "《展示要求》中的信息内容与要求",
        sync: "C-27",
        src: buttonHowBlack,
        srca: buttonHowWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "109px",
            left: "2083px",
            width: "165px",
            height: "35px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 28,
        title: "《展示要求》中的展示方式与要求",
        sencondTitle: "《展示要求》中的展示方式与要求",
        text: "《展示要求》中的展示方式与要求",
        sync: "C-28",
        src: buttonHowBlack,
        srca: buttonHowWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "109px",
            left: "2337px",
            width: "110px",
            height: "50px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 29,
        title: "UGC内容规范",
        sencondTitle: "UGC内容规范",
        text: "UGC内容规范",
        sync: "C-29",
        src: buttonVideoBlack,
        srca: buttonVideoWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "347px",
            left: "2378px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 30,
        title: "UGC账号规范",
        sencondTitle: "UGC账号规范",
        text: "UGC账号规范",
        sync: "C-30",
        src: buttonVideoBlack,
        srca: buttonVideoWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "418px",
            left: "2378px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 31,
        title: "适用范围与总则",
        sencondTitle: "适用范围与总则",
        text: "适用范围与总则",
        sync: "C-31",
        src: buttonPeaBlack,
        srca: buttonPeaWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "495px",
            left: "2366px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 32,
        title: "标题与图片规范",
        sencondTitle: "标题与图片规范",
        text: "标题与图片规范",
        sync: "C-32",
        src: buttonPeaBlack,
        srca: buttonPeaWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "567px",
            left: "2366px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 33,
        title: "内容管理规范",
        sencondTitle: "内容管理规范",
        text: "内容管理规范",
        sync: "C-33",
        src: buttonPeaBlack,
        srca: buttonPeaWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "648px",
            left: "2378px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 34,
        title: "特殊行业内容规范",
        sencondTitle: "特殊行业内容规范",
        text: "特殊行业内容规范",
        sync: "C-34",
        src: buttonPeaBlack,
        srca: buttonPeaWhite,
        style: {
            background: "#84D3FF",
            position: "absolute",
            bottom: "649px",
            left: "2195px",
        },
        menusList: [],
        menusCurrIndex: 1,
    },

    {
        type: "tip",
        text: "开始第3阶段学习",
        style: {
            position: "absolute",
            top: "235px",
            left: "34px",
            color: "black",
            background: "#FFDA26",
        },
    },
    {
        type: "tip",
        text: "结束第3阶段学习",
        style: {
            position: "absolute",
            top: "21px",
            left: "2036px",
            color: "black",
            background: "#FFDA26",
        },
    },

    {
        id: "1",
        type: "arrow",
        direction: "Right",
        style: {
            position: "absolute",
            top: "396px",
            right: "2439px",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "2",
        type: "arrow",
        direction: "Right",
        style: {
            position: "absolute",
            top: "530px",
            right: "2439px",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "3",
        type: "arrow",
        direction: "Right",
        style: {
            position: "absolute",
            top: "602px",
            right: "2439px",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "4",
        type: "arrow",
        direction: "Right",
        style: {
            position: "absolute",
            top: "662px",
            right: "2439px",
            background: `url(${blue}) center no-repeat`,
        },
    },

    {
        id: "5",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "657px",
            right: "1734px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "6",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "594px",
            right: "1734px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "7",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "522px",
            right: "1733px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "8",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "452px",
            right: "1734px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "9",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "381px",
            right: "1733px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "10",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "309px",
            right: "1733px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "11",
        type: "arrow",
        direction: "Up",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "250px",
            right: "1542px",
            transform: "rotateZ(270deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },

    {
        id: "12",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "427px",
            right: "1366px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "13",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "496px",
            right: "1366px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "14",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "567px",
            right: "1366px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "15",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "634px",
            right: "1366px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },

    {
        id: "16",
        type: "arrow",
        direction: "Right",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "568px",
            right: "1226px",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "17",
        type: "arrow",
        direction: "Right",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "500px",
            right: "1226px",
            background: `url(${arrowRight}) center no-repeat`,
        },
    },
    {
        id: "18",
        type: "arrow",
        direction: "Right",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "429px",
            right: "1226px",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "19",
        type: "arrow",
        direction: "Right",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "358px",
            right: "1226px",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "21",
        type: "arrow",
        direction: "Right",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "218px",
            right: "1226px",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "20",
        type: "arrow",
        direction: "Right",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "288px",
            right: "1226px",
            background: `url(${blue}) center no-repeat`,
        },
    },

    {
        id: "22",
        type: "arrow",
        direction: "Right",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "260px",
            right: "813px",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "23",
        type: "arrow",
        direction: "Right",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "329px",
            right: "813px",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "24",
        type: "arrow",
        direction: "Right",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "400px",
            right: "813px",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "25",
        type: "arrow",
        direction: "Right",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "471px",
            right: "813px",
            background: `url(${blue}) center no-repeat`,
        },
    },

    {
        id: "26",
        type: "arrow",
        direction: "Up",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "681px",
            right: "635px",
            transform: "rotateZ(270deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "27",
        type: "arrow",
        direction: "Up",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "681px",
            right: "363px",
            transform: "rotateZ(270deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "28",
        type: "arrow",
        direction: "Up",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "681px",
            right: "137px",
            transform: "rotateZ(270deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },

    {
        id: "29",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "373px",
            right: "3px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "30",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "301px",
            right: "3px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "31",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "224px",
            right: "3px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "32",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "154px",
            right: "3px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "33",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "72px",
            right: "3px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "34",
        type: "arrow",
        direction: "Down",
        src: arrowDown,
        style: {
            position: "absolute",
            top: "24px",
            right: "274px",
            transform: "rotateZ(90deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
];