<template>
  <div class="modal_box" :style="{transform: `scale(${scaleNum})`}">
    <div class="bread_crumb">
      知识路径：{{
        (mainContent.contentDetail.sync).indexOf('A') != -1 && '步步为营' || (mainContent.contentDetail.sync).indexOf('B') != -1 && '学富五车' || '有章有法'
      }} > {{ mainContent.contentDetail.title }} > <span class="thisTime">{{
        mainContent.contentDetail.sencondTitle
      }}</span>
    </div>
    <div class="modal_content">
      <div
          :class="`content_close  ${(mainContent.contentDetail.sync).indexOf('B') != -1 && 'content_close2'} ${(mainContent.contentDetail.sync).indexOf('C') != -1 && 'content_close3'}`">
        <div class="close_button" @click="handleCover">
          X
        </div>
      </div>

      <div class="content_title">
        <span class="head_title">知识点：</span>
        <span class="head_content">{{ mainContent.contentDetail.sencondTitle }}</span>
      </div>

      <div class="content_detail">
        <div class="detail_control" v-if="(mainContent.contentDetail.sync).indexOf('C') == -1">
          <span class="played"></span>
          <span
              class="played_title">{{
              (mainContent.contentDetail.sync).indexOf('A') != -1 ? mainContent.contentDetail.type == 0 ? '知识点详解' : '知识点举例' : (mainContent.contentDetail.sync).indexOf('B') != -1 ? '授课目的' : ''
            }}</span>
        </div>

        <div v-if="(mainContent.contentDetail.sync).indexOf('A') != -1" class="detail_content">
          {{ mainContent.contentDetail.content }}
        </div>

        <div v-if="(mainContent.contentDetail.sync).indexOf('B') != -1" class="video_detail_content">
          <!-- 视频比例 9:6 -->
          <!-- <span v-if="(mainContent.contentDetail.sync).indexOf('C') != -1" >{{ mainContent.contentDetail.content }}</span> -->

          <div v-for="item in videoList" :key="item.id" class="video_box_all">
            <div class="video_box">
              <span>{{ item.title }}</span>
            </div>
            <video controlslist="nodownload" :src="item.url" height="240" width="360" preload="auto" controls
                   @click="playVideo(item)"></video>
            <!-- <video-player class="video-player vjs-custom-skin"
             ref="videoPlayer"
             :playsinline="true"
             style="object-fit:fill"
             :options="playerOptions"
             :x5-video-player-fullscreen="true"
             @pause="onPlayerPause($event)"
             @play="onPlayerPlay($event)"
             @fullscreenchange="onFullscreenChange($event)"
             @click="fullScreen">
            </video-player> -->
          </div>
        </div>

        <div v-if="(mainContent.contentDetail.sync).indexOf('C') != -1" class="video_detail_content">
          <!-- 视频比例 9:6 -->
          <span>{{ mainContent.contentDetail.content }}</span>

          <div v-if="videoList.length >= 3" class="rightButton" @click="turnLeft"></div>
          <div v-if="videoList.length >= 3" class="leftButton" @click="turnRight"></div>

          <div class="videoContent">
            <!-- <video v-for="item in mainContent.contentDetail.videoDtoList" :key="item.id" :src="item.url" height="240" width="360" preload="auto"></video> -->

            <div v-for="item in videoList" :key="item.id" class="video_box_all" :style="{left: `${leftNum}px`  }">
              <div class="video_box" @click="playVideo">
                <span>{{ item.title }}</span>
              </div>
              <video controlslist="nodownload" :src="item.url" height="240" width="360" preload="auto" controls></video>
            </div>
          </div>

        </div>


      </div>

      <div class="content_control">
        <div v-if="mainContent.contentDetail.sync.indexOf('A') != -1 && mainContent.contentDetail.id >= syncId"
             class="sen_timer">
          <div class="sen_content">
            <span>{{ sen }}s</span>后可查看下一条
          </div>
          <span class="clock"></span>
        </div>
        <div class="control_button">

          <div class="closebutton" @click="handleCover">
            关闭
          </div>

          <div
              :class="`nextbutton ${sen == 0 || (mainContent.contentDetail.sync.indexOf('C') != -1 ? (mainContent.contentDetail.videoDtoList[0].id < syncId) : (mainContent.contentDetail.id < syncId)) ? 'actived' : ''}`"
              @click="handelNext(mainContent.contentDetail)">
            下一条
          </div>

          <div class="prebutton" @click="handlePre(mainContent.contentDetail)">
            上一条
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onBeforeUnmount, onMounted, onUpdated, watch} from '@vue/runtime-core';
import {defineComponent, reactive, toRefs} from 'vue'
import {request} from "../api/request"
import {useStore} from 'vuex'
// import VideoPlayer from 'vue-video-player'
// require('video.js/dist/video-js.css')
// require('vue-video-player/src/custom-theme.css')
let interval = null;
export default defineComponent({
  props: ["contentDetail"],
  setup(props) {

    const mainContent = props;
    console.log('moudeModal接收到的参数' + JSON.stringify(props))
    let videoId = mainContent.contentDetail.sync.split('-')[0] == 'C' ? mainContent.contentDetail.videoDtoList[0].id : '';
    let videoList = mainContent.contentDetail.videoDtoList;
    let scaleNum = 1;
    let leftNum = 0;
    const store = useStore();

    const state = reactive({
      mainContent,
      videoList,
      scaleNum,
      leftNum,
      syncId: +store._state.data.knowledgePointId,
      sen: store._state.data.browsingTime != 0 ? store._state.data.browsingTime : 0,
      time: false,
      videoDuration: 0,
      videoId
      // state: false
    })


    const handleCover = function () {
      this.$emit("myclick", 1)
      clearInterval(interval);
      state.leftNum = 0;
      let videoListLI = document.querySelectorAll('video');
      console.log(videoListLI)
      if (videoListLI.length != 0) {
        // for( var i = 0; i <= videoListLI.length; i++ ){
        videoListLI[0].pause()
        store._state.data.browsingTime = videoListLI[0].currentTime
        // }
      }

      request('/iredmanual/updateSchedule', {
        userNodeRecordId: state.mainContent.contentDetail.sync,
        knowledgePointId: state.mainContent.contentDetail.id,
        browsingTime: videoListLI[0]?.currentTime ? videoListLI[0].currentTime : 0
      }, 'POST').then(res => {
            console.log(res)

            request('/iredmanual/getSchedule', '', 'GET').then(res => {
              store.dispatch('progressChange', res.result)
              store.dispatch('nodeChange', res.result.split('/')[0])
              store.dispatch('knowledgeChange', res.result.split('/')[1])
              store.dispatch('browsingChange', res.result.split('/')[2])
            })
            // localStorage.getItem('progress').split('-')[0] = state.mainContent.contentDetail.sync;
            // request('/iredmanual/getSchedule','','GET').then(res => {
            //     console.log('获取进度' + res.result)
            //     localStorage.setItem('progress',res.result)
            // })
          }
      )

    }

    watch(props, () => {
      state.videoList = props.contentDetail.videoDtoList;
      state.syncId = +store._state.data.knowledgePointId;
      // if(props.contentDetail.id >= state.syncId){
      //   state.sen = 20;
      //   countDown();
      // }
    })

    watch(() => {
      store._state.data.knowledgePointId
    },() => {
      state.syncId = +store._state.data.knowledgePointId;
    },{deep:true,immediate:true})

    watch(() => state.sen, () => {
      console.log('当前秒数： ' + state.sen)
      if (state.sen == 0 && mainContent.contentDetail.id == state.syncId) {
        request('/iredmanual/addSchedule', {
          knowledgePointId: state.mainContent.contentDetail.id + 1,
          browsingTime: 20
        }, 'POST').then(res => {
              console.log(res)
              request('/iredmanual/getSchedule', '', 'GET').then(res => {
                store.dispatch('progressChange', res.result)
                store.dispatch('nodeChange', res.result.split('/')[0])
                store.dispatch('knowledgeChange', res.result.split('/')[1])
                store.dispatch('browsingChange', res.result.split('/')[2])
              })
            }
        )
      }
    })

    onMounted(() => {
      console.log('窗口初始高度：' + window.innerHeight)
      console.log(scaleNum)

      if (window.innerHeight <= 937) {
        state.scaleNum = (window.innerHeight / 937).toFixed(2);
      }

      window.addEventListener('resize', (item) => {

        if (item.target.innerHeight <= 937) {
          state.scaleNum = (item.target.innerHeight / 937).toFixed(2);
        }
      });

    })

    const handlePre = function (item) {
      let videoListLI = document.querySelectorAll('video');
      // console.log(videoListLI)
      if (mainContent.contentDetail.id < state.syncId) {
        console.log('上一步')
        // state.sen = 0;
        clearInterval(interval);
        // return;
      }

      state.leftNum = 0;

      if (videoListLI.length != 0) {
        for (var i = 0; i < videoListLI.length; i++) {
          videoListLI[i].pause()
        }
      }

      let url = `/iredmanual/subDetail?id=${item.id - 1}&sync=${item.sync}`;

      if (item.sync.substring(0, 1) == 'C') {
        url = `/iredmanual/menuList?sync=C-${item.sync.substring(2) - 1}`;
      }

      if (item.sync == 'C-1') {
        url = `/iredmanual/subDetail?id=161&sync=B-16`
      }

      request(url, '', 'GET').then(data => {

        state.mainContent.contentDetail.sync = data.result.sync;
        state.mainContent.contentDetail.content = data.result.content;
        state.mainContent.contentDetail.id = data.result.id;
        state.mainContent.contentDetail.title = data.result.title;
        state.mainContent.contentDetail.sencondTitle = data.result.sencondTitle;
        state.videoList = data.result.videoDtoList;
        state.mainContent.contentDetail.type = data.result.type;
        state.mainContent.contentDetail.videoDtoList = data.result.videoDtoList


      })
    }

    const handelNext = function (item) {
      console.log(state.syncId)
      state.time = true;


      if (mainContent.contentDetail.id < state.syncId) {
        console.log('下一步')
        // state.sen = 0;
        clearInterval(interval);
        // return;
      }

      if (state.sen != 0) {
        console.log('下一步秒数不为0')
        return;
      }
      if (mainContent.contentDetail.sync.split('-')[0] == 'C') {
        if (mainContent.contentDetail.videoDtoList[0].id >= store._state.data.knowledgePointId) {
          console.log('下一步C界面 首视频id 大于等于 知识点id')
          return;
        }
      }

      // if(mainContent.contentDetail.id >= store._state.data.knowledgePointId){
      //     return;
      // }


      if (state.sen > 0 && state.mainContent.contentDetail.id >= state.syncId) {
        state.time = false;
        request(`/iredmanual/subDetail?id=${item.id + 1}&sync=${item.sync}`, '', 'GET').then(data => {
          // console.log(data.result.content)

          if (data.result.videoDtoList != []) {
            mainContent.contentDetail.videoDtoList = data.result.videoDtoList;
          }

          state.mainContent.contentDetail.sync = data.result.sync;
          state.mainContent.contentDetail.content = data.result.content;
          state.mainContent.contentDetail.id = data.result.id;
          state.mainContent.contentDetail.title = data.result.title;
          state.mainContent.contentDetail.sencondTitle = data.result.sencondTitle;
          state.mainContent.contentDetail.videoDtoList = data.result.videoDtoList
          state.mainContent.contentDetail.type = data.result.type;

        })
        console.log('秒数大于0, 当前知识点id 大于等于标识 id')
        return;
      }

      state.leftNum = 0;
      // state.sen = 20;
      let videoListLI = document.querySelectorAll('video');
      // console.log(videoListLI)
      if (videoListLI.length != 0) {
        for (var i = 0; i < videoListLI.length; i++) {
          videoListLI[i].pause()
        }
      }
      var num = +item.sync.substring(2) + 1;

      if (item.sync.indexOf('C') != -1) {
        // console.log("这是C的界面")
        request(`/iredmanual/menuList?sync=${'C-' + num}`, '', 'GET').then(data => {
          if (data.result.videoDtoList != []) {
            mainContent.contentDetail.videoDtoList = data.result.videoDtoList;
          }

          state.mainContent.contentDetail.sync = data.result.sync;
          state.mainContent.contentDetail.id = data.result.id;
          state.mainContent.contentDetail.title = data.result.sencondTitle;
          state.mainContent.contentDetail.sencondTitle = data.result.sencondTitle;
          state.mainContent.contentDetail.type = data.result.type;
          mainContent.contentDetail.videoDtoList = data.result.videoDtoList

        })

      } else {
        request(`/iredmanual/subDetail?id=${item.id + 1}&sync=${item.sync}`, '', 'GET').then(data => {
          // console.log(data.result.content)

          if (data.result.videoDtoList != []) {
            mainContent.contentDetail.videoDtoList = data.result.videoDtoList;
          }

          state.mainContent.contentDetail.sync = data.result.sync;
          state.mainContent.contentDetail.content = data.result.content;
          state.mainContent.contentDetail.id = data.result.id;
          state.mainContent.contentDetail.title = data.result.title;
          state.mainContent.contentDetail.sencondTitle = data.result.sencondTitle;
          state.mainContent.contentDetail.videoDtoList = data.result.videoDtoList
          state.mainContent.contentDetail.type = data.result.type;

        })
      }


    }

    const playVideo = function () {
      console.log(1111)
    }

    const countDown = function () {
      // setInterval(function(){
      state.time = false;
      if (state.mainContent.contentDetail.id == state.syncId) {
        state.sen = 20;
        interval = setInterval(function () {
          console.log("当前时间"+state.sen)
          if (state.sen <= 0) {
            state.sen = 0;
            clearInterval(interval);
          } else {
            state.sen--
          }
        }, 1000)
      }
    }

    onMounted(() => {
      if (state.mainContent.contentDetail.sync.indexOf('C') != -1) {
        let videoListLI = document.querySelectorAll('video');
        console.log(videoListLI)
        console.log(videoListLI[0].currentTime)
        videoListLI[0].oncanplay = function () {
          console.log('视频总时长' + videoListLI[0].duration)
        }
        videoListLI[0].addEventListener('ended', () => {
          state.sen = 0;
          console.log('C中视频播放结束')

          if (mainContent.contentDetail.videoDtoList[0].id == store._state.data.knowledgePointId) {
            request('/iredmanual/addSchedule', {
              knowledgePointId: mainContent.contentDetail.videoDtoList[0].id + 1,
              browsingTime: 0
            }, 'POST').then(res => {
                  console.log(res)
                  request('/iredmanual/getSchedule', '', 'GET').then(res => {
                    store.dispatch('progressChange', res.result)
                    store.dispatch('nodeChange', res.result.split('/')[0])
                    store.dispatch('knowledgeChange', res.result.split('/')[1])
                    store.dispatch('browsingChange', res.result.split('/')[2])
                  })
                }
            )
          }
        }, false)
      }

      if (state.mainContent.contentDetail.sync.indexOf('B') != -1) {
        let videoListLi = document.querySelectorAll('video')

        if (videoListLi.length != 0) {
          if (store._state.data.knowledgePointId == state.mainContent.contentDetail.id) {
            videoListLi[0].currentTime = store._state.data.browsingTime;
          } else {
            videoListLi[0].currentTime = 0
          }
        }

        videoListLi[0].addEventListener('ended', () => { //该视频播放是否已结束
          // state.time = !state.time
          // state.state = true
          state.sen = 0;
          console.log('视频播放结束')

          if (mainContent.contentDetail.id == store._state.data.knowledgePointId) {
            request('/iredmanual/addSchedule', {
              knowledgePointId: state.mainContent.contentDetail.id + 1,
              browsingTime: 0
            }, 'POST').then(res => {
                  console.log(res)
                  request('/iredmanual/getSchedule', '', 'GET').then(res => {
                    store.dispatch('progressChange', res.result)
                    store.dispatch('nodeChange', res.result.split('/')[0])
                    store.dispatch('knowledgeChange', res.result.split('/')[1])
                    store.dispatch('browsingChange', res.result.split('/')[2])
                  })
                }
            )
          }

        }, false)
      }

      if (state.mainContent.contentDetail.sync.indexOf('A') != -1) countDown();

      state.videoList = mainContent.contentDetail.videoDtoList;
      // console.log('Modal中的video' + JSON.stringify(videoList))
      // console.log(state.mainContent);
    })

    onUpdated(() => {
      console.log('组件内容更新'+`  当前ID${mainContent.contentDetail.id} 学习最后的ID${state.syncId}`)
      if (mainContent.contentDetail.id < state.syncId) {
        state.sen = 0;
        clearInterval(interval);
      }
      if (state.time && state.sen == 0 && mainContent.contentDetail.id == state.syncId) {
        state.sen = 20;
        clearInterval(interval);
        state.time = false;
        countDown()
      }

      // if(state.mainContent.contentDetail.sync.indexOf('A') != -1) countDown();

      var videoList = document.querySelectorAll('video');
      for (var i = 0; i < videoList.length; i++) {
        console.log(videoList[i].currentTime)
        console.log(videoList[i].duration)
      }

      if (state.time && mainContent.contentDetail.sync.split('-')[0] != 'A') {
        videoList[0].addEventListener('ended', () => { //该视频播放是否已结束
          // state.time = !state.time
          // state.state = true
          state.sen = 0;
          console.log('视频播放结束')

          if (mainContent.contentDetail.id == store._state.data.knowledgePointId) {

            request('/iredmanual/addSchedule', {
              knowledgePointId: state.mainContent.contentDetail.id + 1,
              browsingTime: 0
            }, 'POST').then(res => {
                  console.log(res)
                  request('/iredmanual/getSchedule', '', 'GET').then(res => {
                    store.dispatch('progressChange', res.result)
                    store.dispatch('nodeChange', res.result.split('/')[0])
                    store.dispatch('knowledgeChange', res.result.split('/')[1])
                    store.dispatch('browsingChange', res.result.split('/')[2])
                  })
                }
            )
          }
        }, false)
      }

    })

    onBeforeUnmount(() => {
      console.log('组件被销毁')
    })


    const turnLeft = function () {
      console.log('向左转')
      state.leftNum -= 481;

      if (state.leftNum <= -(Math.floor(state.videoList.length / 2) + 1) * 481) {
        state.leftNum = -(Math.floor(state.videoList.length / 2) + 1) * 481
      }
    }

    const turnRight = function () {
      console.log('向右转')

      state.leftNum += 481;
      if (state.leftNum >= 0) {
        state.leftNum = 0;
      }

    }

    return {
      ...toRefs(state),
      handleCover,
      handlePre,
      handelNext,
      playVideo,
      turnLeft,
      turnRight
    }
  }
});
</script>

<style lang="less" scoped>
.modal_box {
  color: white;
  height: 800px;
  backdrop-filter: blur(10px) brightness(40%);

  .bread_crumb {
    display: flex;
    font-size: 12px;
    height: 40px;
    line-height: 40px;
    min-width: 450px;
    justify-content: left;

    .thisTime {
      color: #5FCCB6;
    }
  }

  .modal_content {
    width: 1080px;
    height: 720px;
    display: flex;
    flex-direction: column;
    background: rgba(124, 124, 124, .3);
    padding: 20px 40px;
    border-radius: 12px;

    .content_close {
      width: 100%;
      height: 239px;
      margin-bottom: 10px;
      background-image: url('../assets/home/moudle_1_top_img.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;

      .close_button {
        width: 30px;
        height: 30px;
        font-size: 15px;
        line-height: 30px;
        text-align: center;
        border-radius: 50%;
        background: #000;
        position: absolute;
        right: 0px;
        cursor: pointer;
      }
    }

    .content_close2 {
      width: 100%;
      height: 239px;
      background-image: url('../assets/home/moudle_2_top_img.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .content_close3 {
      width: 100%;
      height: 239px;
      background-image: url('../assets/home/moudle_3_top_img.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .content_title {
      display: flex;
      margin-bottom: 30px;
      text-align: left;

      .head_title {
        height: 34px;
        width: 150px;
        font-size: 34px;
        line-height: 39.84px;
        font-weight: lighter;
      }

      .head_content {
        display: flex;
        flex-direction: column;
        align-self: flex-end;
        height: 20px;
        font-size: 20px;
        line-height: 21px;
        font-weight: bold;
      }
    }

    .content_detail {
      flex: 1;
      display: flex;
      flex-direction: column;

      .detail_control {
        display: flex;
        color: #5FCCB6;
        margin-bottom: 19px;
        cursor: pointer;

        .played {
          width: 15px;
          height: 15px;
          background: url('../assets/modal/polygon.png') no-repeat center;
        }

        .played_title {
          line-height: 16px;
          padding-left: 5px;
        }
      }

      .detail_content {
        text-align: left;
        height: 300px;
        overflow-y: auto;
      }

      .video_detail_content {
        display: flex;
        flex-direction: column;
        text-align: left;
        color: #7C7C7C;
        font-size: 18px;
        position: relative;
        overflow: hidden;

        video {
          margin-left: 60px;
        }

        .leftButton {
          position: absolute;
          height: 90px;
          width: 60px;
          left: 17px;
          top: 72px;
          background: url(../assets/video/left.png) no-repeat center;
          cursor: pointer;
          z-index: 99;
        }

        .rightButton {
          position: absolute;
          height: 90px;
          width: 60px;
          right: 17px;
          top: 72px;
          background: url(../assets/video/right.png) no-repeat center;
          cursor: pointer;
          z-index: 99;

        }

        .video_box_all {
          cursor: pointer;
          position: relative;

          .video_box {
            position: absolute;
            // border: 1px solid white;
            left: 60px;
            width: 360px;
            height: 240px;
            overflow: hidden;
            // background-color: #000;
            // background: url('../assets/video/playvideo.png') center no-repeat;
            span {
              position: absolute;
              width: 100%;
              bottom: 0;
              color: white;
              text-align: center;
              font-size: 14px;
            }
          }
        }

        .videoContent {
          display: flex;
          overflow: hidden;
          padding: 0 60px;
          position: relative;

          video {
            margin-right: 60px;
          }

        }
      }

    }

    .content_control {
      display: flex;
      flex-direction: column;

      .sen_timer {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 15px;

        .clock {
          width: 20px;
          height: 20px;
          background-image: url('../assets/modal/sen_time.png');
        }

        span {
          color: #DABB28;
        }
      }

      .control_button {
        display: flex;
        flex-direction: row-reverse;

        div {
          width: 120px;
          height: 38px;
          line-height: 38px;
          font-size: 12px;
          color: #5FCCB6;
          border: 1px solid #5FCCB6;
          border-radius: 6px;
          margin-left: 20px;
          cursor: pointer;
        }

        .nextbutton {
          color: #707070;
          border: 1px solid #707070;
        }

        .actived {
          background: #5FCCB6;
          color: white;
        }

        .closebutton {
          color: white;
          background: #707070;
          border: 1px solid #707070;
        }
      }
    }

  }
}

@media only screen and(max-width: 1466px) {
  .modal_box {
    transform: scale(0.68);
  }

  .modal_box .bread_crumb {
    font-size: 18px;
  }

  .head_content {
    font-size: 18px;
  }

  .detail_content {
    font-size: 20px;
  }
}
</style>
