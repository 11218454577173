import axios from 'axios'
import router from "@/router";
import store from "@/store";
// import { useStore } from 'vuex'
// import store from '../store/index'
// var store = useStore();

//全局配置
// axios.defaults.baseURL = "https://ired-resource.api.zhuhuakeji.com"
// axios.defaults.baseURL = "http://192.168.31.131:23456"
axios.defaults.baseURL = "/irededu"
axios.defaults.timeout = 5000
axios.defaults.headers.common['X-Access-Token'] = sessionStorage.getItem('token');
axios.defaults.headers.post['Content-Type'] = 'application/json';
//拦截器
axios.interceptors.request.use(config => {
    if (sessionStorage.getItem('token') !== null) {
        config.headers["X-Access-Token"] = sessionStorage.getItem('token');
    }
    return config
}, error => {
    return Promise.error(error)
})
axios.interceptors.response.use(response => {
    if (response.status === 500) {
        console.log("Eee")
    }
    return response.data
}, (error) => {
    if (error.response.status === 500) {
        if (error.response.data.message === 'Token失效，请重新登录') {
            sessionStorage.removeItem('token');
            store.dispatch("resetStore")
            router.replace({
                path: "/"
            })
        }
    }
    return Promise.reject(error)
})

export function request(url = '', params = {}, type = 'POST') {
    //设置 url params type 的默认值
    return new Promise((resolve, reject) => {
        let promise
        if (type.toUpperCase() === 'GET') {
            promise = axios({
                url,
                params
            })
        } else if (type.toUpperCase() === 'POST') {
            promise = axios({
                method: 'POST',
                url,
                data: params
            })
        }
        //处理返回
        promise.then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
