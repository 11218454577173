import moudle1TopImg from '@/assets/home/moudle_2_top_img.png';

import bottomGround from '@/assets/moudle2/bottom.png'
import bottomGroud3 from '@/assets/paster/bottom_group3.png'
import groupBottom from '@/assets/moudle2/Group_bottom.png'

import groupCar from '@/assets/moudle2/Group_car.png'
import groupHouse1 from '@/assets/moudle2/Group_house1.png'
import groupHouse2 from '@/assets/moudle2/Group_house2.png'
import groupCross from '@/assets/moudle2/GroupCross.png'
import groupElCloud from '@/assets/moudle2/GroupElCloud.png'
import housesCuate from '@/assets/moudle2/HousesCuate.png'

import titleBack from '@/assets/home/titleBack2.png'
import steel from '@/assets/home/steel.png'

// import arrowUp from '@/assets/moudleArrow/arrow_up.png'
// import arrowLeft from '@/assets/moudleArrow/arrow_left.png'
import arrowRight from '@/assets/moudleArrow/arrow_right.png'

import yellow_arrow from '@/assets/moudleArrow/yellow.png'



export default [
  {
    type: 'img',
    src: moudle1TopImg,
    style: {
      position: "absolute",
      top: "-28px",
      left: "-52px",
      width: "686px",
      height: "249px",
    }
  },
  {
    type: 'img',
    src: bottomGround,
    style: {
      position: "absolute",
      bottom: "-27px",
      left: "0px"
    }
  },
  {
    type: 'img',
    src: steel,
    style: {
      position: "absolute",
      top: "502px",
      left: "539px"
    }
  },
  {
    type: 'img',
    src: steel,
    style: {
      position: "absolute",
      top: "588px",
      left: "180px"
    }
  },
  {
    type: 'img',
    src: bottomGroud3,
    style: {
      position: "absolute",
      bottom: "-12px",
      left: "-45px",
      transform: 'rotateY(180deg)'
    }
  },
  {
    type: 'img',
    src: groupBottom,
    style: {
      position: "absolute",
      bottom: "-26px",
      left: "447px",
      zIndex: 99

    }
  },
  {
    type: 'img',
    src: groupCar,
    style: {
      position: "absolute",
      top: "50px",
      left: "759px"
    }
  },
  {
    type: 'img',
    src: groupHouse1,
    style: {
      position: "absolute",
      top: "547px",
      left: "167px"
    }
  },
  {
    type: 'img',
    src: groupHouse2,
    style: {
      position: "absolute",
      top: "330px",
      left: "162px"
    }
  },
  {
    type: 'img',
    src: groupCross,
    style: {
      position: "absolute",
      top: "485px",
      left: "1056px"
    }
  },
  {
    type: 'img',
    src: groupElCloud,
    style: {
      position: "absolute",
      top: "313px",
      left: "975px"
    }
  },
  {
    type: 'img',
    src: housesCuate,
    style: {
      position: "absolute",
      top: "614px",
      left: "1530px"
    }
  },
  // pasterpaper


  // titleBack

  {
    type: 'img',
    src: titleBack,
    style: {
      position: "absolute",
      top: "413px",
      left: "1469px"
    }
  },
  {
    type: 'img',
    src: titleBack,
    style: {
      position: "absolute",
      top: "92px",
      left: "1460px"
    }
  },
  {
    type: 'img',
    src: titleBack,
    style: {
      position: "absolute",
      top: "471px",
      left: "919px"
    }
  },
  {
    type: 'img',
    src: titleBack,
    style: {
      position: "absolute",
      top: "627px",
      left: "487px"
    }
  },
  {
    type: 'img',
    src: titleBack,
    style: {
      position: "absolute",
      top: "497px",
      left: "34px"
    }
  },
  {
    type: 'img',
    src: titleBack,
    style: {
      position: "absolute",
      top: "282px",
      left: "34px"
    }
  },
  {
    type: 'tip',
    text: "直播专业知识",
    style: {
      position: "absolute",
      top: "295px",
      left: "19px",
      width: "92px",
      height: "53px",
      color: "white",
      background: 'none'
    }
  }, {
    type: 'tip',
    text: "电商直播专业知识",
    style: {
      position: "absolute",
      top: "500px",
      left: "21px",
      width: "92px",
      height: "53px",
      color: "white",
      background: 'none'
    }
  }, {
    type: 'tip',
    text: "直播前",
    style: {
      position: "absolute",
      top: "643px",
      left: "472px",
      width: "92px",
      height: "53px",
      color: "white",
      background: 'none'
    }
  }, {
    type: 'tip',
    text: "直播中",
    style: {
      position: "absolute",
      top: "485px",
      left: "903px",
      width: "92px",
      height: "53px",
      color: "white",
      background: 'none'
    }
  }, {
    type: 'tip',
    text: "直播后",
    style: {
      position: "absolute",
      top: "105px",
      left: "1443px",
      width: "92px",
      height: "53px",
      color: "white",
      background: 'none'
    }
  }, {
    type: 'tip',
    text: "直播与短视频",
    style: {
      position: "absolute",
      top: "426px",
      left: "1456px",
      width: "87px",
      height: "53px",
      color: "white",
      background: 'none'
    }
  },

  {
    type: 'menuTip', // 黄色的标语卡片类型
    text: '行业基础',
    sync: 'B-1',
    style: {
      position: "absolute",
      top: "350px",
      left: "153px",
      background: "#FFDA26"
    },
    menusList: [
      {
        type: '2',
        text: '直播与直播营销的含义，直播营销的作用与特点'
      },
      {
        type: '2',
        text: '直播平台分类与主播特点'
      },
      {
        type: '2',
        text: '直播营销的团队分工及职责'
      }
    ]
  },
  {
    type: 'menuTip', // 黄色的标语卡片类型
    text: '行业准则',
    sync: 'B-2',
    style: {
      position: "absolute",
      top: "580px",
      left: "153px",
      background: "#FFDA26"
    }
  },
  {
    type: 'menuTip', // 黄色的标语卡片类型
    text: '权限与操作',
    sync: 'B-3',
    style: {
      position: "absolute",
      top: "497px",
      left: "512px",
      background: "#FFDA26",
      zIndex: 102
    }
  },
  {
    type: 'menuTip', // 黄色的标语卡片类型
    text: '主播人设',
    sync: 'B-4',
    style: {
      position: "absolute",
      top: "418px",
      left: "512px",
      background: "#FFDA26",
      zIndex: 102
    }
  },
  {
    type: 'menuTip', // 黄色的标语卡片类型
    text: '文案撰写',
    sync: 'B-5',
    style: {
      position: "absolute",
      top: "337px",
      left: "512px",
      background: "#FFDA26",
      zIndex: 102
    }
  },
  {
    type: 'menuTip', // 黄色的标语卡片类型
    text: '脚本制作及准备',
    sync: 'B-7',
    style: {
      position: "absolute",
      top: "425px",
      left: "700px",
      background: "#FFDA26",
      zIndex: 102
    }
  },
  {
    type: 'menuTip', // 黄色的标语卡片类型
    text: '封面图拍摄与制作',
    sync: 'B-6',
    style: {
      position: "absolute",
      top: "338px",
      left: "685px",
      background: "#FFDA26",
      zIndex:"102"
    }
  },
  {
    type: 'menuTip', // 黄色的标语卡片类型
    text: '   软件操作   ',
    sync: 'B-8',
    style: {
      position: "absolute",
      top: "505px",
      left: "1063px",
      background: "#FFDA26",
      zIndex: 99
    }
  },
  {
    type: 'menuTip', // 黄色的标语卡片类型
    text: '产品卖点',
    sync: 'B-9',
    style: {
      position: "absolute",
      top: "505px",
      left: "1215px",
      background: "#FFDA26",
      zIndex: 99
    }
  },
  {
    type: 'menuTip', // 黄色的标语卡片类型
    text: '直播节奏',
    sync: 'B-10',
    style: {
      position: "absolute",
      top: "421px",
      left: "1216px",
      background: "#FFDA26",
      zIndex: 100
    }
  },
  {
    type: 'menuTip', // 黄色的标语卡片类型
    text: '开场白与镜头表达',
    sync: 'B-11',
    style: {
      position: "absolute",
      top: "342px",
      left: "1161px",
      background: "#FFDA26",
      zIndex: 100
    }
  },
  {
    type: 'menuTip', // 黄色的标语卡片类型
    text: '商品介绍与展示',
    sync: 'B-12',
    style: {
      position: "absolute",
      top: "258px",
      left: "1175px",
      background: "#FFDA26",
      zIndex: 100
    }
  },
  {
    type: 'menuTip', // 黄色的标语卡片类型
    text: '互动与促单',
    sync: 'B-13',
    style: {
      position: "absolute",
      top: "177px",
      left: "1203px",
      background: "#FFDA26",
      zIndex: 100
    }
  },
  {
    type: 'menuTip', // 黄色的标语卡片类型
    text: '突发事件处理及流程赏析',
    sync: 'B-14',
    style: {
      position: "absolute",
      top: "97px",
      left: "1120px",
      background: "#FFDA26",
      zIndex: 100
    }
  },
  {
    type: 'menuTip', // 黄色的标语卡片类型
    text: '直播数据总结',
    sync: 'B-15',
    style: {
      position: "absolute",
      top: "236px",
      left: "1512px",
      background: "#FFDA26",
      zIndex: 120
    }
  },
  {
    type: 'menuTip', // 黄色的标语卡片类型
    text: '直播“带货”与短视频“种草”',
    sync: 'B-16',
    style: {
      position: "absolute",
      top: "546px",
      left: "1505px",
      background: "#FFDA26",
      zIndex: 100
    }
  },

  {
    type: 'tip',
    text: "开始第2阶段学习",
    style: {
      position: "absolute",
      top: "235px",
      left: "34px",
      color: "black",
      background: '#FFDA26'
    }
  }, {
    type: 'tip',
    text: "结束第2阶段学习",
    style: {
      position: "absolute",
      top: "602px",
      left: "1419px",
      color: "black",
      background: '#FFDA26'
    }
  },


  {
    id: '1',
    type: 'arrow',
    direction: 'Right',
    src: yellow_arrow,
    style: {
      position: "absolute",
      top: "356px",
      right: "1641px",
      background: `url(${yellow_arrow}) center no-repeat`
    }
  },
  {
    id: '2',
    type: 'arrow',
    direction: 'Right',
    src: arrowRight,
    style: {
      position: "absolute",
      top: "584px",
      right: "1640px",
      background: `url(${yellow_arrow}) center no-repeat`
    }
  },
  {
    id: '3',
    type: 'arrow',
    direction: 'Right',
    src: arrowRight,
    style: {
      position: "absolute",
      top: "503px",
      right: "1258px",
      background: `url(${yellow_arrow}) center no-repeat`
    }
  },
  {
    id: '4',
    type: 'arrow',
    direction: 'Right',
    src: arrowRight,
    style: {
      position: "absolute",
      top: "423px",
      right: "1259px",
      background: `url(${yellow_arrow}) center no-repeat`
    }
  },
  {
    id: '5',
    type: 'arrow',
    direction: 'Right',
    src: arrowRight,
    style: {
      position: "absolute",
      top: "341px",
      right: "1259px",
      background: `url(${yellow_arrow}) center no-repeat`
    }
  },

  {
    id: '6',
    type: 'arrow',
    direction: 'Left',
    src: arrowRight,
    style: {
      position: "absolute",
      top: "342px",
      right: "873px",
      transform: 'rotateY(180deg)',
      background: `url(${yellow_arrow}) center no-repeat`

    }
  },
  {
    id: '7',
    type: 'arrow',
    direction: 'Left',
    src: arrowRight,
    style: {
      position: "absolute",
      top: "428px",
      right: "873px",
      transform: 'rotateY(180deg)',
      background: `url(${yellow_arrow}) center no-repeat`
    }
  },

  {
    id: '8',
    type: 'arrow',
    direction: 'Up',
    src: arrowRight,
    style: {
      position: "absolute",
      top: "560px",
      right: "629px",
      transform: 'rotateZ(270deg)',
      background: `url(${yellow_arrow}) center no-repeat`
    }
  }, {
    id: '9',
    type: 'arrow',
    direction: 'Up',
    src: arrowRight,
    style: {
      position: "absolute",
      top: "560px",
      right: "478px",
      transform: 'rotateZ(270deg)',
      background: `url(${yellow_arrow}) center no-repeat`
    }
  },


  {
    id: '10',
    type: 'arrow',
    direction: 'Left',
    src: arrowRight,
    style: {
      position: "absolute",
      top: "419px",
      right: "400px",
      transform: 'rotateY(180deg)',
      background: `url(${yellow_arrow}) center no-repeat`
    }
  },
  {
    id: '11',
    type: 'arrow',
    direction: 'Left',
    src: arrowRight,
    style: {
      position: "absolute",
      top: "345px",
      right: "401px",
      transform: 'rotateY(180deg)',
      background: `url(${yellow_arrow}) center no-repeat`
    }
  },
  {
    id: '12',
    type: 'arrow',
    direction: 'Left',
    src: arrowRight,
    style: {
      position: "absolute",
      top: "262px",
      right: "401px",
      transform: 'rotateY(180deg)',
      background: `url(${yellow_arrow}) center no-repeat`
    }
  },
  {
    id: '13',
    type: 'arrow',
    direction: 'Left',
    src: arrowRight,
    style: {
      position: "absolute",
      top: "182px",
      right: "401px",
      transform: 'rotateY(180deg)',
      background: `url(${yellow_arrow}) center no-repeat`
    }
  },
  {
    id: '14',
    type: 'arrow',
    direction: 'Left',
    src: arrowRight,
    style: {
      position: "absolute",
      top: "101px",
      right: "392px",
      transform: 'rotateY(180deg)',
      background: `url(${yellow_arrow}) center no-repeat`
    }
  },

  {
    id: '15',
    type: 'arrow',
    direction: 'Right',
    src: arrowRight,
    style: {
      position: "absolute",
      top: "240px",
      right: "261px",
      background: `url(${yellow_arrow}) center no-repeat`
    }
  }, {
    id: '16',
    type: 'arrow',
    direction: 'Right',
    src: arrowRight,
    style: {
      position: "absolute",
      top: "551px",
      right: "261px",
      background: `url(${yellow_arrow}) center no-repeat`
    }
  },

];